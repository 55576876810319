
import {Component, Vue} from 'vue-property-decorator';
import {shops, shopRequests, deliveryMerchants} from '../../../../../resources';
import {Form} from 'element-ui';
import scopeMap from '@/components/scopeMap/index.vue';
import moment from 'moment';
import {ObjectId} from 'bson';
import {PlatformConfig} from '@/externals/MaxCI-PlatformConfig-v1';
import {Shop, Polygon, TimeRange} from '@/externals/MaxCI-Shop-v1';
import {AxiosError} from 'axios';
import uploadImage from '@/components/uploadImage/index.vue';
import {DeliveryMerchant} from '@/externals/MaxCI-DeliveryMerchant-v1';

@Component({
  name: 'deliveryShop',
  components: {
    scopeMap,
    uploadImage,
  },
})
export default class extends Vue {
  private validRate = (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    rule: any,
    value: string,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    callback: (error?: Error) => void,
  ) => {
    if (!/(^[1-9]\d*(\.\d{1,4})?$)|(^0(\.\d{1,4})?$)/.test(value)) {
      callback(new Error('费率必须为0-30之间的数值'));
    } else if (Number(value) > 30 || Number(value) < 0) {
      callback(new Error('费率必须为0-30之间的数值'));
    } else {
      callback();
    }
  };
  private validprice = (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    rule: any,
    value: string,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    callback: (error?: Error) => void,
  ) => {
    if (!/(^[1-9]\d*(\.\d{1,2})?$)|(^0(\.\d{1,2})?$)/.test(value)) {
      callback(new Error('服务费格式有误'));
    } else {
      callback();
    }
  };
  private type = '应用配送';
  private detailId = ObjectId.createFromHexString(
    this.$route.query.id as string,
  );
  private shopInfo = null as null | Shop;
  private platformConfig: null | PlatformConfig = null; //平台配置信息
  private submitFlag = false; //提交开关
  private applicationData = {
    enable: false,
    deliveryId: '',
    shopId: '',
    serviceRate: {
      rate: null as null | number,
      minimum: null as null | number,
    },
    serviceScopes: [
      {
        scope: [] as [number, number][][],
        minimums: null as null | number,
        deliveryFee: null as null | number,
        times: [
          {
            begin: '00:00',
            end: '24:00',
          },
        ] as Array<TimeRange>,
      },
    ],
  };
  private applicationRules = {
    deliveryId: [{required: true, message: '请选择商户', trigger: 'blur'}],
    shopId: [{required: true, message: '请输入门店ID', trigger: 'blur'}],
    'serviceRate.rate': [
      {required: true, message: '请输入配送服务费率', trigger: 'blur'},
      {validator: this.validRate, trigger: 'blur'},
    ],
    'serviceRate.minimum': [
      {required: true, message: '请输入保底服务费', trigger: 'blur'},
      {validator: this.validprice, trigger: 'blur'},
    ],
  };
  private shopData = {
    enable: false,
    serviceScopes: [
      {
        scope: [] as [number, number][][],
        minimums: 0,
        deliveryFee: 0,
        times: [
          {
            begin: '00:00',
            end: '24:00',
          },
        ] as Array<TimeRange>,
      },
    ],
  };
  private role = localStorage.getItem('role');
  private deliveryMerchantData = [] as Array<DeliveryMerchant>;
  private applicationId = ObjectId.createFromHexString(
    this.$route.params.applicationId,
  );
  private tecServiceRate = 0; //店铺技术服务费率

  async created() {
    try {
      if (this.role !== '店铺') {
        //查询配送商户
        this.deliveryMerchantData = await deliveryMerchants.find(stage =>
          stage.$match(match =>
            match(
              f => f('spec')('applicationId'),
              e => e.$eq(this.applicationId),
            ),
          ),
        );
      }
      //获取店铺详情
      this.shopInfo =
        (
          await shops.find(stage =>
            stage.$match(match =>
              match(
                f => f('_id'),
                e => e.$eq(this.detailId),
              ),
            ),
          )
        ).find(() => true) ?? null;
      if (this.shopInfo) {
        if (this.shopInfo.spec.tecServiceRate) {
          this.tecServiceRate =
            (this.shopInfo.spec.tecServiceRate.rate * 100) / 10000;
        }
        if (this.shopInfo.spec.applicationDelivery) {
          const applicationDelivery = this.shopInfo.spec.applicationDelivery;
          this.applicationData.enable = applicationDelivery.enable;
          this.applicationData.deliveryId =
            applicationDelivery?.deliveryId?.toHexString() ?? '';
          this.applicationData.shopId = applicationDelivery.shopId ?? '';
          this.applicationData.serviceRate = {
            rate: applicationDelivery?.serviceRate?.rate
              ? (applicationDelivery?.serviceRate?.rate * 100) / 10000
              : 0,
            minimum: applicationDelivery?.serviceRate?.minimum
              ? applicationDelivery?.serviceRate?.minimum / 100
              : 0,
          };
          if (applicationDelivery?.serviceScopes) {
            this.applicationData.serviceScopes =
              applicationDelivery?.serviceScopes?.map(v => {
                return {
                  times: v.times,
                  minimums: v.minimums / 100,
                  deliveryFee: v.deliveryFee / 100,
                  scope: (v.scope as Polygon).coordinates,
                };
              });
          }
        }
        if (this.shopInfo.spec.shopDelivery) {
          const shopDelivery = this.shopInfo.spec.shopDelivery;
          this.shopData.enable = shopDelivery.enable;
          if (shopDelivery?.serviceScopes) {
            this.shopData.serviceScopes = shopDelivery?.serviceScopes?.map(
              v => {
                return {
                  times: v.times,
                  minimums: v.minimums / 100,
                  deliveryFee: v.deliveryFee / 100,
                  scope: (v.scope as Polygon).coordinates,
                };
              },
            );
          }
        }
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      this.$message.error('网络异常，请稍后重试');
    }
  }
  //图片显示连接
  get downloadUrl() {
    return this.$store.state.pConfig.downloadUrl;
  }
  //选择配送商户
  private changeDelivery(e: string) {
    for (let item of this.deliveryMerchantData) {
      if (item._id.toHexString() === e) {
        this.applicationData.serviceScopes[0].times =
          item.spec.serviceScopes[0].times;
        this.applicationData.serviceScopes[0].scope = (
          item.spec.serviceScopes[0].scope as Polygon
        ).coordinates;
        this.applicationData.serviceScopes[0].minimums =
          item.spec.serviceScopes[0].minimums / 100;
        this.applicationData.serviceScopes[0].deliveryFee =
          item.spec.serviceScopes[0].deliveryFee / 100;
      }
    }
  }
  //获取地图坐标范围数组
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private getScopePosi(arr: any, index: number) {
    const type = this.type === '应用配送' ? 'applicationData' : 'shopData';
    if (arr.length !== 0) {
      const serviceScope = [] as [number, number][];
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      arr.forEach((item: any) => {
        if (item.lng) {
          serviceScope.push([item.lng, item.lat]);
        } else {
          serviceScope.push(item);
        }
      });
      serviceScope.push(serviceScope[0]);
      this[type].serviceScopes[index].scope = [serviceScope];
    }
  }
  //当前时间减去10分钟
  reduceTime(time: string) {
    return moment(time, 'HH:mm').subtract(10, 'minutes').format('HH:mm');
  }
  //增加营业时间
  private addTime(index: number) {
    const type = this.type === '应用配送' ? 'applicationData' : 'shopData';
    let num = 0;
    if (this[type].serviceScopes[index].times) {
      this[type].serviceScopes[index].times.forEach(item => {
        if (item.begin === null || item.end === null) {
          num++;
          this.$message.error('营业时间请填写完整');
        }
      });
      if (num === 0) {
        this[type].serviceScopes[index].times.push({
          begin: '',
          end: '',
        });
      }
    }
  }
  //移出营业时间
  private removeTime(timeIndex: number, index: number) {
    const type = this.type === '应用配送' ? 'applicationData' : 'shopData';
    if (
      this[type].serviceScopes[index].times &&
      this[type].serviceScopes[index].times.length !== 1
    ) {
      this[type].serviceScopes[index].times.splice(timeIndex, 1);
    }
  }
  //选择营业时间的开始时间校验结束时间
  private changeTime(e: string, timeIndex: number, index: number) {
    const type = this.type === '应用配送' ? 'applicationData' : 'shopData';
    if (
      this[type].serviceScopes[index].times &&
      this[type].serviceScopes[index].times[timeIndex]?.end
    ) {
      if (
        Number(e.split(':')[0]) >
          Number(
            this[type].serviceScopes[index].times[timeIndex]?.end?.split(
              ':',
            )[0],
          ) ||
        (Number(e.split(':')[0]) ===
          Number(
            this[type].serviceScopes[index].times[timeIndex]?.end?.split(
              ':',
            )[0],
          ) &&
          Number(e.split(':')[1]) >=
            Number(
              this[type].serviceScopes[index].times[timeIndex]?.end?.split(
                ':',
              )[1],
            ))
      ) {
        this[type].serviceScopes[index].times[timeIndex].end = '24:00';
      }
    }
  }
  //新增范围
  private addScope() {
    const type = this.type === '应用配送' ? 'applicationData' : 'shopData';
    this[type].serviceScopes.push({
      scope: [],
      minimums: 0,
      deliveryFee: 0,
      times: [
        {
          begin: '00:00',
          end: '24:00',
        },
      ],
    });
  }
  //删除范围
  private delScope(index: number) {
    const type = this.type === '应用配送' ? 'applicationData' : 'shopData';
    this[type].serviceScopes.splice(index, 1);
  }
  //修改
  private async submitForm() {
    const type = this.type === '应用配送' ? 'applicationData' : 'shopData';
    (this.$refs[type] as Form).validate(async valid => {
      if (valid) {
        try {
          if (this.type === '应用配送') {
            if (
              Number(this.applicationData.serviceRate.rate) +
                this.tecServiceRate >
              30
            ) {
              this.$message.error(
                '配送服务费率和店铺技术服务费率之和不得大于30',
              );
              return;
            }
          }
          if (this[type].serviceScopes.length > 0) {
            for (let item of this[type].serviceScopes) {
              if (
                !/(^[1-9]\d*(\.\d{1,2})?$)|(^0(\.\d{1,2})?$)/.test(
                  (item.minimums ?? '').toString(),
                )
              ) {
                this.$message.error('起送价格式不正确');
                return;
              }
              if (
                !/(^[1-9]\d*(\.\d{1,2})?$)|(^0(\.\d{1,2})?$)/.test(
                  (item.deliveryFee ?? '').toString(),
                )
              ) {
                this.$message.error('配送费格式不正确');
                return;
              }
              if (item.times.length === 0) {
                this.$message.error('请选择配送时间');
                return;
              }
              if (item.scope.length === 0) {
                this.$message.error('请选择范围');
                return;
              }
            }
          }
          const serviceScopes = this[type].serviceScopes.map(v => {
            return {
              minimums: Math.round(Number(v.minimums) * 100),
              deliveryFee: Math.round(Number(v.deliveryFee) * 100),
              times: v.times,
              scope: {type: 'Polygon', coordinates: v.scope} as Polygon,
            };
          });
          this.submitFlag = true;
          if (this.role === '店铺') {
            const shopRequest = await shopRequests.create([
              {
                spec: {
                  shopId: this.detailId,
                  applicationId: this.applicationId,
                  ...(type === 'applicationData'
                    ? {
                        applicationDelivery: {
                          enable: true,
                          serviceScopes: serviceScopes,
                          deliveryId: ObjectId.createFromHexString(
                            this[type].deliveryId,
                          ),
                          shopId: this[type].shopId,
                          serviceRate: {
                            rate:
                              (Number(this[type].serviceRate.rate) * 10000) /
                              100,

                            minimum: Math.round(
                              Number(this[type].serviceRate.minimum) * 100,
                            ),
                          },
                        },
                      }
                    : {
                        shopDelivery: {
                          enable: true,
                          serviceScopes: serviceScopes,
                        },
                      }),
                },
              },
            ]);
            if (shopRequest) {
              this.$message.success('保存成功');
              this.backBtn();
            }
          } else {
            const shop = await shops.update(
              filter =>
                filter(
                  f => f('_id'),
                  e => e.$eq(this.detailId),
                )(
                  f => f('spec')('applicationId'),
                  e => e.$eq(this.applicationId),
                ),
              update =>
                update.$set(s => {
                  if (type === 'applicationData') {
                    s(
                      f => f('spec')('applicationDelivery'),
                      this[type].enable
                        ? {
                            enable: true,
                            serviceScopes: serviceScopes,
                            deliveryId: ObjectId.createFromHexString(
                              this[type].deliveryId,
                            ),
                            shopId: this[type].shopId,
                            serviceRate: {
                              rate:
                                (Number(this[type].serviceRate.rate) * 10000) /
                                100,
                              minimum: Math.round(
                                Number(this[type].serviceRate.minimum) * 100,
                              ),
                            },
                          }
                        : {enable: false},
                    );
                  } else {
                    s(
                      f => f('spec')('shopDelivery'),
                      this[type].enable
                        ? {
                            enable: true,
                            serviceScopes: serviceScopes,
                          }
                        : {enable: false},
                    );
                  }
                  return s;
                }),
            );
            if (shop.length > 0) {
              this.$message.success('保存成功');
              this.backBtn();
            }
          }
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (e: any) {
          if (e.isAxiosError) {
            const axiosError = e as AxiosError;
            if (
              axiosError.response?.status === 500 &&
              axiosError.response.data.message.indexOf('E11000') > -1
            ) {
              this.$message.error('名称不能重复');
            }
          }
        } finally {
          this.submitFlag = false;
        }
      } else {
        return false;
      }
    });
  }
  //返回
  private backBtn() {
    this.$router.push({
      query: {
        menu: this.$route.query.menu,
        type: 'list',
      },
    });
  }
}
