
import {Component, Vue} from 'vue-property-decorator';
import {applications, applicationRequests} from '../../../../resources';
import {ObjectId} from 'bson';
import {
  StatisticsTemplate,
  ApplicationServiceConfig,
} from '@/externals/MaxCI-Application-v1';
import {Form} from 'element-ui';
import uploadImage from '@/components/uploadImage/index.vue';

@Component({
  name: 'mallSetting',
  components: {
    uploadImage,
  },
})
export default class extends Vue {
  private validRate = (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    rule: any,
    value: string,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    callback: (error?: Error) => void,
  ) => {
    if (!/^(1|(0(.\d+)?))$/.test(value)) {
      callback(new Error('请输入0-1的数值'));
    } else {
      callback();
    }
  };
  private serviceConfigs: null | ApplicationServiceConfig = null;
  private statistics: Array<StatisticsTemplate> = [];
  private formData = {
    printCancel: false,
    printLogo: '',
  };
  private rules = {};
  private saveLoading = false;
  async created() {
    try {
      const application = (
        await applications.find(stage =>
          stage.$match(match => {
            match(
              f => f('_id'),
              e =>
                e.$eq(
                  ObjectId.createFromHexString(
                    this.$route.params.applicationId,
                  ),
                ),
            );
            return match;
          }),
        )
      ).find(() => true);
      this.serviceConfigs = application?.spec.serviceConfigs ?? null;
      this.formData.printCancel =
        application?.spec.serviceConfigs?.商城?.printCancel ?? false;
      this.formData.printLogo =
        application?.spec.serviceConfigs?.商城?.printLogo ?? '';
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      this.$message.error('网络异常，请稍后重试');
    }
  }
  //选择打印logo
  private changeUploadImage(id: string) {
    this.formData.printLogo = id;
  }
  private submitSave() {
    (this.$refs.formData as Form).validate(valid => {
      if (valid) {
        let serviceConfigs = null as null | ApplicationServiceConfig;
        if (this.serviceConfigs) {
          if (this.serviceConfigs.商城) {
            serviceConfigs = this.serviceConfigs;
            serviceConfigs.商城 = {
              statistics: this.serviceConfigs.商城?.statistics
                ? this.serviceConfigs.商城.statistics
                : [],
              printCancel: this.formData.printCancel,
              printLogo: this.formData.printLogo,
            };
          } else {
            serviceConfigs = {
              ...this.serviceConfigs,
              商城: {
                statistics: [],
                printCancel: this.formData.printCancel,
                printLogo: this.formData.printLogo,
              },
            };
          }
        } else {
          serviceConfigs = {
            商城: {
              statistics: [],
              printCancel: this.formData.printCancel,
              printLogo: this.formData.printLogo,
            },
          };
        }
        this.saveLoading = true;
        try {
          applicationRequests
            .create(
              [
                {
                  spec: {
                    type: '更新应用',
                    applicationId: ObjectId.createFromHexString(
                      this.$route.params.applicationId,
                    ),
                    serviceConfigs: serviceConfigs,
                  },
                },
              ],
              {
                watch: {
                  filter: filter =>
                    filter(
                      f => f('operationType'),
                      e => e.$eq('update'),
                    )(
                      f => f('fullDocument')('status')('phase'),
                      e => e.$exists(true),
                    ),
                },
                fullResource: true,
              },
            )
            .then(res => {
              this.saveLoading = false;
              if (res[0].status?.phase === '成功') {
                this.$message.success('保存成功');
              } else {
                this.$message.error(res[0].status?.conditions[0].message ?? '');
              }
            });
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (e: any) {
          this.saveLoading = false;
          this.$message.error('网络异常，请稍后重试');
        }
      } else {
        return false;
      }
    });
  }
}
