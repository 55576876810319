
import {Component, Vue} from 'vue-property-decorator';
import Pagination from '@/components/Pagination/index.vue';
import {shopLabels, shops} from '../../../../../resources';
import moment from 'moment';
import {ObjectId} from 'bson';
import draggable from 'vuedraggable';
import inputClose from '@/components/inputClose/index.vue';
import {AxiosError} from 'axios';
import $ from 'jquery';

interface List {
  _id: ObjectId | null;
  name: string;
  values: Array<string>;
  multiSelect: boolean;
  value: string;
  verification: boolean;
  warnPrompt: boolean;
}
@Component({
  name: 'shopLabelList',
  components: {
    Pagination,
    draggable,
    inputClose,
  },
})
export default class extends Vue {
  private options = {filter: '.stopdrap', preventOnFilter: false}; //拖拽组件配置
  private operateId: ObjectId | null = null;
  private operateName = '';
  private dialogDel = false; //删除弹窗
  private searchData = {name: ''};
  private total = 0;
  private list: Array<List> = [];
  private oldList: Array<List> = [];
  private listLoading = true;
  private listParams = {
    page: 1,
    limit: 10,
  };
  private applicationId = this.$route.params.applicationId;

  async created() {
    this.updateList();
  }
  //新增
  private async addBtn() {
    // await this.checkList();
    this.list.unshift({
      _id: null,
      name: '',
      values: [],
      multiSelect: false,
      value: '',
      verification: false,
      warnPrompt: false,
    });
    $('#name_text0').focus();
  }
  private idToString(id: ObjectId | null) {
    if (id) {
      return id.toHexString();
    } else {
      return '';
    }
  }
  //转换时间
  getTime(time: string) {
    return moment(time).format('YYYY-MM-DD HH:mm:ss');
  }
  //搜索数据
  private checkList() {
    this.listParams.page = 1;
    this.updateList();
  }
  private async updateList() {
    this.listLoading = true;
    try {
      const list = await shopLabels.find(stage =>
        stage
          .$match(match => {
            if (this.searchData.name) {
              match(
                f => f('spec')('name'),
                e => e.$regex(new RegExp(this.searchData.name)),
              );
            }
            match(
              f => f('spec')('applicationId'),
              e =>
                e.$eq(
                  ObjectId.createFromHexString(
                    this.$route.params.applicationId,
                  ),
                ),
            );
            return match;
          })
          .$facet(facet =>
            facet('table', tableStage =>
              tableStage
                .$sort(sort =>
                  sort(f => f('metadata')('creationTimestamp'), '降序'),
                )
                .$skip((this.listParams.page - 1) * this.listParams.limit)
                .$limit(this.listParams.limit),
            )('count', countStage => countStage.$count('count')),
          ),
      );
      this.total = list[0].count[0] ? list[0].count[0].count.valueOf() : 0;
      const isLabel = list[0].table.find(v => v.spec.name === '个性标签');
      if (isLabel) {
        this.list = list[0].table.map(v => {
          return {
            _id: v._id,
            name: v.spec.name,
            values: v.spec.values.map(v => v),
            multiSelect: v.spec.multiSelect,
            value: '',
            verification: false,
            warnPrompt: false,
          };
        });
        this.oldList = list[0].table.map(v => {
          return {
            _id: v._id,
            name: v.spec.name,
            values: v.spec.values.map(v => v),
            multiSelect: v.spec.multiSelect,
            value: '',
            verification: false,
            warnPrompt: false,
          };
        });
      } else {
        const shopLabel = await shopLabels.create([
          {
            spec: {
              name: '个性标签',
              values: [],
              multiSelect: false,
              applicationId: ObjectId.createFromHexString(this.applicationId),
            },
          },
        ]);
        if (shopLabel.length > 0) {
          this.updateList();
        }
      }

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      this.$message.error('网络异常，请稍后重试');
    } finally {
      this.listLoading = false;
    }
  }
  //添加标签的值
  private async addValue(index: number) {
    let num = 0;
    this.list[index].values.forEach(item => {
      if (item === this.list[index].value) {
        num++;
      }
    });
    this.list[index].verification = false;
    this.list[index].warnPrompt = false;
    if (this.list[index].value) {
      if (num === 0) {
        if (this.list[index].value.length > 15) {
          this.list[index].warnPrompt = true;
        } else {
          const shopLabel = await shopLabels.update(
            filter =>
              filter(
                f => f('_id'),
                e => e.$eq(this.list[index]._id as ObjectId),
              )(
                f => f('spec')('applicationId'),
                e =>
                  e.$eq(
                    ObjectId.createFromHexString(
                      this.$route.params.applicationId,
                    ),
                  ),
              ),
            update =>
              update.$push(s =>
                s(f => f('spec')('values'), this.list[index].value),
              ),
          );
          if (shopLabel.length > 0) {
            this.updateList();
          }
        }
      } else {
        this.list[index].verification = true;
      }
    }
  }
  //修改名称
  private async changeName(index: number) {
    if (this.list[index]._id) {
      if (this.list[index].name) {
        if (this.list[index].name !== this.oldList[index].name) {
          const shopLabel = await shopLabels.update(
            filter =>
              filter(
                f => f('_id'),
                e => e.$eq(this.list[index]._id as ObjectId),
              )(
                f => f('spec')('applicationId'),
                e =>
                  e.$eq(
                    ObjectId.createFromHexString(
                      this.$route.params.applicationId,
                    ),
                  ),
              ),
            update =>
              update.$set(s =>
                s(f => f('spec')('name'), this.list[index].name),
              ),
          );
          const oldLabel = this.oldList[index].values.map(v => {
            return this.oldList[index].name + ':' + v;
          });
          const label = this.list[index].values.map(v => {
            return this.list[index].name + ':' + v;
          });
          if (shopLabel.length > 0) {
            oldLabel.forEach((item, index) => {
              this.changeLabel(item, label[index]);
            });
          }
        }
      } else {
        this.list[index].name = this.oldList[index].name;
      }
    } else {
      if (this.list[index].name) {
        try {
          const shopLabel = await shopLabels.create([
            {
              spec: {
                name: this.list[index].name,
                values: [],
                multiSelect: false,
                applicationId: ObjectId.createFromHexString(this.applicationId),
              },
            },
          ]);
          if (shopLabel.length > 0) {
            this.updateList();
          }
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (e: any) {
          if (e.isAxiosError) {
            const axiosError = e as AxiosError;
            if (
              axiosError.response?.status === 500 &&
              (axiosError.response.data.name === 'MongoError' ||
                axiosError.response.data.name === 'Error') &&
              axiosError.response.data.message.indexOf('E11000') > -1
            ) {
              this.$message.error('标签名称不能重复');
            }
          }
        }
      } else {
        this.list.shift();
      }
    }
  }
  //店铺修改标签
  private async changeLabel(oldName: string, name: string) {
    await shops.update(
      filter =>
        filter(
          f => f('spec')('labels'),
          e => e.$eq(oldName),
        )(
          f => f('spec')('applicationId'),
          e =>
            e.$eq(
              ObjectId.createFromHexString(this.$route.params.applicationId),
            ),
        ),
      update => update.$set(s => s(f => f('spec')('labels')('$'), name)),
    );
  }
  //修改是否多选
  private changeMultiSelect(id: ObjectId, e: boolean) {
    shopLabels.update(
      filter =>
        filter(
          f => f('_id'),
          e => e.$eq(id),
        )(
          f => f('spec')('applicationId'),
          e =>
            e.$eq(
              ObjectId.createFromHexString(this.$route.params.applicationId),
            ),
        ),
      update => update.$set(s => s(f => f('spec')('multiSelect'), e)),
    );
  }
  //删除页面提示
  private delPopUps(id: ObjectId, name: string) {
    this.operateId = id;
    this.operateName = name;
    this.dialogDel = true;
  }
  //删除页面保存
  private async delSumbit() {
    try {
      if (this.operateId) {
        const shopLabel = await shopLabels.delete(filter =>
          filter(
            f => f('_id'),
            e => e.$eq(this.operateId as ObjectId),
          )(
            f => f('spec')('applicationId'),
            e =>
              e.$eq(
                ObjectId.createFromHexString(this.$route.params.applicationId),
              ),
          ),
        );
        if (shopLabel) {
          this.dialogDel = false;
          this.operateId = null;
          shops
            .update(
              filter => filter,
              update =>
                update.$pull(s =>
                  s(
                    f => f('spec')('labels'),
                    'expression',
                    e => e.$regex(new RegExp(`^${this.operateName}:.+$`)),
                  ),
                ),
            )
            .then(res => {
              if (res.length > 0) {
                this.$message.success('已删除');
                this.updateList();
              }
            });
        }
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      this.$message.error('网络异常，请稍后重试');
    }
  }
  private changePosition(index: number) {
    shopLabels.update(
      filter =>
        filter(
          f => f('_id'),
          e => e.$eq(this.list[index]._id as ObjectId),
        ),
      update =>
        update.$set(s => s(f => f('spec')('values'), this.list[index].values)),
    );
  }
}
