
import {Component, Vue} from 'vue-property-decorator';
import Pagination from '@/components/Pagination/index.vue';
import {
  payTransactions,
  payMerchants,
  orders,
  shops,
} from '../../../../../resources';
import {ObjectId} from 'bson';
import moment from 'moment';
import {PayTransaction} from '@/externals/MaxCI-PayTransaction-v1';
import {EJSON} from 'bson';

@Component({
  name: 'billingDetailsInfo',
  components: {
    Pagination,
  },
})
export default class extends Vue {
  private loading = false;
  private detailMsg: null | PayTransaction = null;
  private detailId = ObjectId.createFromHexString(
    this.$route.query.id as string,
  );
  private payMerchantName = '';
  private shopName = '';
  private userName = '';
  private payTime = '';
  private role = localStorage.getItem('role');
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private shopAuthority: any = localStorage.getItem('shopAuthority')
    ? EJSON.parse(localStorage.getItem('shopAuthority') ?? '')
    : '';
  async created() {
    try {
      this.detailMsg =
        (
          await payTransactions.find(stage =>
            stage.$match(match => {
              match(
                f => f('_id'),
                e => e.$eq(this.detailId),
              )(
                f => f('spec')('applicationId'),
                e =>
                  e.$eq(
                    ObjectId.createFromHexString(
                      this.$route.params.applicationId,
                    ),
                  ),
              );
              if (this.role !== '应用') {
                match(
                  f => f('spec')('shopId'),
                  e => e.$in(this.shopAuthority),
                );
              }
              return match;
            }),
          )
        ).find(() => true) ?? null;
      if (this.detailMsg) {
        if (this.role === '应用') {
          //查询商户名称
          this.payMerchantName =
            (
              await payMerchants.find(stage =>
                stage.$match(match =>
                  match(
                    f => f('_id'),
                    e => e.$eq(this.detailMsg?.spec.payMerchantId as ObjectId),
                  )(
                    f => f('spec')('applicationId'),
                    e =>
                      e.$eq(
                        ObjectId.createFromHexString(
                          this.$route.params.applicationId,
                        ),
                      ),
                  ),
                ),
              )
            ).find(() => true)?.spec.name ?? '';
        }

        //查询店铺名称
        if (this.detailMsg?.spec.orderType === '商城') {
          const shopId = this.detailMsg?.spec.shopId;
          this.shopName =
            (
              await shops.find(stage =>
                stage.$match(match =>
                  match(
                    f => f('_id'),
                    e => e.$eq(shopId),
                  )(
                    f => f('spec')('applicationId'),
                    e =>
                      e.$eq(
                        ObjectId.createFromHexString(
                          this.$route.params.applicationId,
                        ),
                      ),
                  ),
                ),
              )
            ).find(() => true)?.spec.name ?? '';
        }
        //买家账号
        const order =
          (
            await orders.find(stage =>
              stage.$match(match => {
                match(
                  f => f('_id'),
                  e => e.$eq(this.detailMsg?.spec.orderId as ObjectId),
                )(
                  f => f('spec')('applicationId'),
                  e =>
                    e.$eq(
                      ObjectId.createFromHexString(
                        this.$route.params.applicationId,
                      ),
                    ),
                );
                if (this.role !== '应用') {
                  match(
                    f => f('spec')('shopId'),
                    e => e.$in(this.shopAuthority),
                  );
                }
                return match;
              }),
            )
          ).find(() => true) ?? null;
        if (order) {
          if (order.spec.type === '商城') {
            this.userName = order.spec.deliveryInfo?.info.phone ?? '';
          }
          order?.status?.conditions.forEach(item => {
            if (item.type === '支付') {
              this.payTime = this.getTime(item.creationTimestamp);
            }
          });
        }
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      this.$message.error('网络异常，请稍后重试');
    }
  }
  //查看订单
  private checkOrder(id: ObjectId) {
    this.$router.push({
      path: '/application/' + this.$route.params.applicationId + '/order/index',
      query: {
        menu: '订单列表',
        type: 'detail',
        id: id.toHexString(),
      },
    });
  }
  //转换时间
  getTime(time: string | Date) {
    return moment(time).format('YYYY-MM-DD HH:mm:ss');
  }
  //返回
  private bactBtn() {
    this.$router.push({
      query: {menu: this.$route.query.menu, type: 'list'},
    });
  }
}
