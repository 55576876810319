
import {Component, Vue} from 'vue-property-decorator';
import {applications, applicationRequests} from '../../../../../resources';
import {ObjectId} from 'bson';
import {
  StatisticsTemplate,
  ApplicationServiceConfig,
} from '@/externals/MaxCI-Application-v1';
import draggable from 'vuedraggable';
@Component({
  name: 'statisticsTemplate',
  components: {
    draggable,
  },
})
export default class extends Vue {
  private serviceConfigs: null | ApplicationServiceConfig = null;
  private statistics: Array<StatisticsTemplate> = [];
  async created() {
    try {
      const application = (
        await applications.find(stage =>
          stage.$match(match => {
            match(
              f => f('_id'),
              e =>
                e.$eq(
                  ObjectId.createFromHexString(
                    this.$route.params.applicationId,
                  ),
                ),
            );
            return match;
          }),
        )
      ).find(() => true);
      this.serviceConfigs = application?.spec.serviceConfigs ?? null;
      this.statistics =
        application?.spec.serviceConfigs?.商城?.statistics ?? [];
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      this.$message.error('网络异常，请稍后重试');
    }
  }
  private submitSave() {
    let num = 0;
    this.statistics.forEach(item => {
      if (!item.name || !item.tepmlate) {
        this.$message.error('名称和模板不能为空');
        num++;
      }
    });
    if (num !== 0) {
      return false;
    }
    let serviceConfigs = null as null | ApplicationServiceConfig;
    if (this.serviceConfigs) {
      if (this.serviceConfigs.商城) {
        serviceConfigs = this.serviceConfigs;
        serviceConfigs.商城 = {
          statistics: this.statistics,
          printCancel: this.serviceConfigs.商城?.printCancel
            ? this.serviceConfigs.商城.printCancel
            : false,
          serviceRate: this.serviceConfigs.商城?.serviceRate
            ? this.serviceConfigs.商城.serviceRate
            : 0,
          printLogo: this.serviceConfigs.商城?.printLogo
            ? this.serviceConfigs.商城.printLogo
            : '',
        };
      } else {
        serviceConfigs = {
          ...this.serviceConfigs,
          商城: {
            statistics: this.statistics,
            printCancel: false,
            serviceRate: 0,
            printLogo: '',
          },
        };
      }
    } else {
      serviceConfigs = {
        商城: {
          statistics: this.statistics,
          printCancel: false,
          serviceRate: 0,
          printLogo: '',
        },
      };
    }
    try {
      applicationRequests
        .create(
          [
            {
              spec: {
                type: '更新应用',
                applicationId: ObjectId.createFromHexString(
                  this.$route.params.applicationId,
                ),
                serviceConfigs: serviceConfigs,
              },
            },
          ],
          {
            watch: {
              filter: filter =>
                filter(
                  f => f('operationType'),
                  e => e.$eq('update'),
                )(
                  f => f('fullDocument')('status')('phase'),
                  e => e.$exists(true),
                ),
            },
            fullResource: true,
          },
        )
        .then(res => {
          if (res[0].status?.phase === '成功') {
            this.$message.success('保存成功');
          } else {
            this.$message.error(res[0].status?.conditions[0].message ?? '');
          }
        });
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      this.$message.error('网络异常，请稍后重试');
    }
  }
  //新增模板
  private addTem() {
    this.statistics.push({
      name: '',
      tepmlate: '',
      color: '#000000',
      description: '',
    });
  }
  private delTem(index: number) {
    this.statistics.splice(index, 1);
  }
}
