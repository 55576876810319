
import {Component, Vue} from 'vue-property-decorator';
import {
  shopLabels,
  platformConfigs,
  shops,
  shopRequests,
  lushpayMerchants,
} from '../../../../../resources';
import {Form} from 'element-ui';
import scopeMap from '@/components/scopeMap/index.vue';
import moment from 'moment';
import {ObjectId} from 'bson';
import {ShopLabel} from '@/externals/MaxCI-ShopLabel-v1';
import {PlatformConfig} from '@/externals/MaxCI-PlatformConfig-v1';
import {
  ServiceTime,
  GeometrySchema,
  ShopReserveDays,
} from '@/externals/MaxCI-Shop-v1';
import {AxiosError} from 'axios';
import uploadImage from '@/components/uploadImage/index.vue';
import {LushpayMerchant} from '@/externals/MaxCI-LushpayMerchant-v1';

interface ShopValue extends ShopLabel {
  values: Array<{
    name: string;
    checked: boolean;
  }>;
}
@Component({
  name: 'editShop',
  components: {
    scopeMap,
    uploadImage,
  },
})
export default class extends Vue {
  private validRate = (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    rule: any,
    value: string,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    callback: (error?: Error) => void,
  ) => {
    if (!/(^[1-9]\d*(\.\d{1,4})?$)|(^0(\.\d{1,4})?$)/.test(value)) {
      callback(new Error('费率必须为0-30之间的数值'));
    } else if (Number(value) > 30 || Number(value) < 0) {
      callback(new Error('费率必须为0-30之间的数值'));
    } else {
      callback();
    }
  };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private validName = (rule: any, value: string, callback: any) => {
    if (value.length > 32 || value.length < 2) {
      callback(new Error('名称必须长度必须小于32个字符且大于2个字符'));
    } else {
      callback();
    }
  };
  private validprice = (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    rule: any,
    value: string,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    callback: (error?: Error) => void,
  ) => {
    if (!/(^[1-9]\d*(\.\d{1,2})?$)|(^0(\.\d{1,2})?$)/.test(value)) {
      callback(new Error('服务费格式有误'));
    } else {
      callback();
    }
  };
  private validNum = (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    rule: any,
    value: string,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    callback: (error?: Error) => void,
  ) => {
    if (!/(^[1-9]\d*$)/.test(value)) {
      callback(new Error('请输入正整数'));
    } else {
      callback();
    }
  };
  private validScore = (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    rule: any,
    value: string,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    callback: (error?: Error) => void,
  ) => {
    if (!/(^[1-9]\d*(\.\d{1,2})?$)|(^0(\.\d{1,2})?$)/.test(value)) {
      callback(new Error('评分格式错误'));
    } else {
      callback();
    }
  };
  private validPhone = (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    rule: any,
    value: string,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    callback: (error?: Error) => void,
  ) => {
    if (
      /^(([0-9]{3,4}[-])?[0-9]{7,8}$)/.test(value) ||
      /(^1[23456789]\d{9}$)/.test(value)
    ) {
      callback();
    } else {
      callback(new Error('电话格式不正确'));
    }
  };
  private detailId = ObjectId.createFromHexString(
    this.$route.query.id as string,
  );
  private mouseVal: null | number = null; //鼠标滑动选中的时间
  private clickIndex = 0; //选择的是开始时间或结束时间
  private timeIndex = 1000; //当前选中选择框
  private booktime = {
    begin: 100,
    end: 100,
  }; //预订时间
  private beginData = [
    {name: '今天', value: 0},
    {name: '明天', value: 1},
    {name: '后天', value: 2},
    {name: '4天', value: 3},
    {name: '5天', value: 4},
    {name: '6天', value: 5},
    {name: '7天', value: 6},
    {name: '8天', value: 7},
    {name: '9天', value: 8},
    {name: '10天', value: 9},
    {name: '11天', value: 10},
    {name: '12天', value: 11},
    {name: '13天', value: 12},
    {name: '14天', value: 13},
    {name: '15天', value: 14},
    {name: '16天', value: 15},
    {name: '17天', value: 16},
    {name: '18天', value: 17},
    {name: '19天', value: 18},
    {name: '20天', value: 19},
    {name: '21天', value: 20},
    {name: '22天', value: 21},
    {name: '23天', value: 22},
    {name: '24天', value: 23},
    {name: '25天', value: 24},
    {name: '26天', value: 25},
    {name: '27天', value: 26},
    {name: '28天', value: 27},
    {name: '29天', value: 28},
    {name: '30天', value: 29},
  ];
  private dialogLabel = false; //选择标签弹窗
  private activeLabel = [0];
  private labelData: Array<ShopValue> = []; //标签数据
  private platformConfig: null | PlatformConfig = null; //平台配置信息
  private submitFlag = false; //提交开关
  private formData = {
    name: '',
    address: '',
    logo: '',
    phone: '',
    serviceTime: {
      weeks: [true, true, true, true, true, true, true],
      times: [
        {
          begin: '00:00',
          end: '24:00',
        },
      ],
    } as ServiceTime,
    auto: {
      autoAccept: false,
      autoDeliver: false,
    },
    reserve: {
      acceptReserve: false,
      acceptCloseReserve: false,
    },
    timeInterval: 1, //间隔时间（分钟）
    stockUpTime: 30, //备货时长（分钟）
    acceptDays: [
      {
        times: {
          begin: '00:00',
          end: '24:00',
        },
        days: {
          begin: 0,
          end: 1,
        },
      },
    ], // 接受预定天数
    description: '',
    labels: [] as Array<string>,
    geometry: {
      type: 'Point',
      coordinates: [0, 0] as [number, number],
    } as GeometrySchema,
    applicationId: ObjectId.createFromHexString(
      this.$route.params.applicationId,
    ) as ObjectId,
    fullReductions: [
      {
        condition: null,
        promptCondition: '',
        discount: null,
        promptDiscount: '',
      },
    ] as Array<{
      condition: null | number;
      discount: null | number;
      promptCondition: string;
      promptDiscount: string;
    }>, //满减
    score: 0, //店铺评分
    alipayShopId: '', //支付宝门店ID
    merchantId: '', //青山支付
    selfPick: false, //到店自取
    tecServiceRate: {
      rate: null as null | number,
      minimum: null as null | number,
    },
  };
  private rules = {
    name: [
      {required: true, message: '请输入店铺名称', trigger: 'blur'},
      {validator: this.validName, trigger: 'blur'},
    ],
    address: [{required: true, message: '请输入店铺地址', trigger: 'blur'}],
    phone: [
      {required: true, message: '请输入店铺电话', trigger: 'blur'},
      {validator: this.validPhone, trigger: 'blur'},
    ],
    logo: [{required: true, message: '请上传店铺图标', trigger: 'blur'}],
    timeInterval: [
      {required: true, message: '请输入间隔时间', trigger: 'blur'},
      {validator: this.validNum, trigger: 'blur'},
    ],
    stockUpTime: [
      {required: true, message: '请输入备货时长', trigger: 'blur'},
      {validator: this.validNum, trigger: 'blur'},
    ],
    score: [
      {required: true, message: '请输入店铺评分', trigger: 'blur'},
      {validator: this.validScore, trigger: 'blur'},
    ],
    'tecServiceRate.rate': [
      {required: true, message: '请输入技术服务费率', trigger: 'blur'},
      {validator: this.validRate, trigger: 'blur'},
    ],
    'tecServiceRate.minimum': [
      {required: true, message: '请输入保底服务费', trigger: 'blur'},
      {validator: this.validprice, trigger: 'blur'},
    ],
  };
  private lushpayMerchantData: Array<LushpayMerchant> = [];
  private role = localStorage.getItem('role');
  private serviceRate = 0; //店铺应用配送服务费率

  async created() {
    try {
      if (this.role !== '店铺') {
        //查询青山支付
        this.lushpayMerchantData = await lushpayMerchants.find(stage =>
          stage.$match(match =>
            match(
              f => f('spec')('applicationId'),
              e => e.$eq(this.formData.applicationId),
            ),
          ),
        );
      }
      //查询标签数据
      const list = (await shopLabels.find(stage =>
        stage.$match(match =>
          match(
            f => f('spec')('applicationId'),
            e => e.$eq(this.formData.applicationId),
          ),
        ),
      )) as Array<ShopValue>;
      list.forEach(item => {
        item.values = [];
        if (item.values) {
          item.spec.values.forEach(valueItem => {
            item.values.push({
              name: valueItem,
              checked: false,
            });
          });
        }
      });
      this.labelData = list;
      //查询平台信息
      this.platformConfig =
        (await platformConfigs.find(stage => stage)).find(() => true) ?? null;
      //获取店铺详情
      const detailMsg = (
        await shops.find(stage =>
          stage.$match(match =>
            match(
              f => f('_id'),
              e => e.$eq(this.detailId),
            ),
          ),
        )
      ).find(() => true)?.spec;
      if (detailMsg) {
        this.formData.score = detailMsg.score;
        this.formData.name = detailMsg.name;
        this.formData.alipayShopId = detailMsg.alipayShopId ?? '';
        this.formData.address = detailMsg.address;
        this.formData.logo = detailMsg.logo;
        this.formData.phone = detailMsg.phone;
        this.formData.selfPick = detailMsg.selfPick;
        this.formData.serviceTime = detailMsg.serviceTime;
        this.formData.auto = detailMsg.auto;
        this.formData.stockUpTime = detailMsg.stockUpTime ?? 30;
        if (detailMsg.reserve.acceptReserve) {
          this.formData.reserve = {
            acceptReserve: detailMsg.reserve.acceptReserve,
            acceptCloseReserve: detailMsg.reserve.acceptCloseReserve,
          };
          this.formData.timeInterval = detailMsg.reserve.timeInterval;
          this.formData.acceptDays = detailMsg.reserve.acceptDays.map(v => {
            return {
              times: {
                begin: v.times.begin ?? '',
                end: v.times.end ?? '',
              },
              days: {
                begin: v.days.begin ?? 0,
                end: v.days.end ?? 1,
              },
            };
          });
        } else if (!detailMsg.reserve.acceptReserve) {
          this.formData.reserve.acceptReserve = detailMsg.reserve.acceptReserve;
        }
        this.formData.description = detailMsg.description;
        this.formData.geometry = detailMsg.geometry;
        //满减
        const fullReductions = [] as Array<{
          condition: null | number;
          discount: null | number;
          promptCondition: string;
          promptDiscount: string;
        }>;
        if ((detailMsg.fullReductions ?? []).length === 0) {
          fullReductions.push({
            condition: null,
            discount: null,
            promptCondition: '',
            promptDiscount: '',
          });
        } else {
          (detailMsg.fullReductions ?? []).forEach(item => {
            fullReductions.push({
              condition: Number(item.condition) / 100,
              discount: Number(item.discount) / 100,
              promptCondition: '',
              promptDiscount: '',
            });
          });
        }
        this.formData.fullReductions = fullReductions;
        this.formData.merchantId = detailMsg.merchantId ?? '';
        //标签
        this.formData.labels = detailMsg.labels;
        this.formData.labels.forEach(item => {
          const itemData = item.split(':');
          this.labelData.forEach(labelItem => {
            if (itemData[0] === labelItem.spec.name) {
              labelItem.values.forEach(valueItem => {
                if (itemData[1] === valueItem.name) {
                  valueItem.checked = true;
                }
              });
            }
          });
        });
        if (detailMsg.tecServiceRate) {
          this.formData.tecServiceRate = {
            rate: (detailMsg.tecServiceRate.rate * 100) / 10000,
            minimum: detailMsg.tecServiceRate.minimum / 100,
          };
        }
        if (
          detailMsg.applicationDelivery &&
          detailMsg.applicationDelivery.enable
        ) {
          this.serviceRate =
            (detailMsg.applicationDelivery.serviceRate?.rate * 100) / 10000;
        }
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      this.$message.error('网络异常，请稍后重试');
    }
  }
  //选择营业时间的开始时间校验结束时间
  private changeTime(e: string, index: number) {
    if (
      this.formData.serviceTime?.times &&
      this.formData.serviceTime?.times[index]?.end
    ) {
      if (
        Number(e.split(':')[0]) >
          Number(this.formData.serviceTime?.times[index]?.end?.split(':')[0]) ||
        (Number(e.split(':')[0]) ===
          Number(this.formData.serviceTime?.times[index]?.end?.split(':')[0]) &&
          Number(e.split(':')[1]) >=
            Number(this.formData.serviceTime?.times[index]?.end?.split(':')[1]))
      ) {
        this.formData.serviceTime.times[index].end = '24:00';
      }
    }
  }
  //减 失去焦点
  private reduceBlur(val: number, index: number) {
    this.formData.fullReductions[index].promptDiscount = '';
    const regPrice = /^\d+(\.\d{0,2})?$/;
    if (val) {
      if (regPrice.test(val.toString())) {
        if (
          Number(this.formData.fullReductions[index].condition) <=
          Number(this.formData.fullReductions[index].discount)
        ) {
          this.formData.fullReductions[index].promptDiscount =
            "'减'金额必须小于'满'金额";
          return;
        }
        if (this.formData.fullReductions[index].condition) {
          for (let i = 0; i < this.formData.fullReductions.length; i++) {
            if (i > index) {
              if (
                Number(this.formData.fullReductions[index].discount) >=
                  Number(this.formData.fullReductions[i].discount) &&
                Number(this.formData.fullReductions[index].condition) <
                  Number(this.formData.fullReductions[i].condition)
              ) {
                this.formData.fullReductions[index].promptDiscount =
                  '当前优惠档次已存在';
                return;
              }
            }
            if (i < index) {
              if (
                Number(this.formData.fullReductions[index].discount) <=
                  Number(this.formData.fullReductions[i].discount) &&
                Number(this.formData.fullReductions[index].condition) >
                  Number(this.formData.fullReductions[i].condition)
              ) {
                this.formData.fullReductions[index].promptDiscount =
                  '当前优惠档次已存在';
                return;
              }
            }
          }
          this.formData.fullReductions.sort((a, b) => {
            return (
              (a.condition ?? Number.MAX_SAFE_INTEGER) -
              (b.condition ?? Number.MAX_SAFE_INTEGER)
            );
          });
        }
      } else {
        this.formData.fullReductions[index].promptDiscount = '金额格式不正确';
      }
    }
  }
  //满 失去焦点
  private fullBlur(val: number, index: number) {
    this.formData.fullReductions[index].promptDiscount = '';
    this.formData.fullReductions[index].promptCondition = '';
    const regPrice = /^\d+(\.\d{0,2})?$/;
    if (val) {
      if (regPrice.test(val.toString())) {
        if (
          Number(this.formData.fullReductions[index].condition) <=
          Number(this.formData.fullReductions[index].discount)
        ) {
          this.formData.fullReductions[index].promptDiscount =
            "'减'金额必须小于'满'金额";
          return;
        }
        for (let i = 0; i < this.formData.fullReductions.length; i++) {
          if (i !== index) {
            if (
              Number(val) === Number(this.formData.fullReductions[i].condition)
            ) {
              this.formData.fullReductions[i].promptCondition =
                this.formData.fullReductions[index].promptCondition =
                  '当前满减档次已存在';
              return;
            }
          }
        }
        if (this.formData.fullReductions[index].discount) {
          for (let i = 0; i < this.formData.fullReductions.length; i++) {
            if (i > index) {
              if (
                Number(this.formData.fullReductions[index].discount) >=
                  Number(this.formData.fullReductions[i].discount) &&
                Number(this.formData.fullReductions[index].condition) <
                  Number(this.formData.fullReductions[i].condition)
              ) {
                this.formData.fullReductions[index].promptDiscount =
                  '当前优惠档次已存在';
              }
            }
            if (i < index) {
              if (
                Number(this.formData.fullReductions[index].discount) <=
                  Number(this.formData.fullReductions[i].discount) &&
                Number(this.formData.fullReductions[index].condition) >
                  Number(this.formData.fullReductions[i].condition)
              ) {
                this.formData.fullReductions[index].promptDiscount =
                  '当前优惠档次已存在';
              }
            }
          }
          this.formData.fullReductions.sort((a, b) => {
            return (
              (a.condition ?? Number.MAX_SAFE_INTEGER) -
              (b.condition ?? Number.MAX_SAFE_INTEGER)
            );
          });
        }
      } else {
        this.formData.fullReductions[index].promptCondition = '金额格式不正确';
      }
    }
  }
  //添加满减
  private addReduce(index: number) {
    this.formData.fullReductions.splice(index + 1, 0, {
      condition: null,
      promptCondition: '',
      discount: null,
      promptDiscount: '',
    });
  }
  //清空
  private emptybtn() {
    this.formData.fullReductions = [
      {
        condition: null,
        promptCondition: '',
        discount: null,
        promptDiscount: '',
      },
    ];
  }
  //减少满减
  private delReduce(index: number) {
    this.formData.fullReductions.splice(index, 1);
  }
  //处理标签数据
  private getLabel(label: string, type: string) {
    let value = '';
    if (type === 'name') {
      value = label.split(':')[0];
    } else {
      value = label.split(':')[1];
    }
    return value;
  }
  //选择标签保存
  private labelSumbit() {
    const labels = [] as Array<string>;
    this.labelData.forEach(item => {
      item.values.forEach(valueItem => {
        if (valueItem.checked) {
          labels.push(item.spec.name + ':' + valueItem.name);
        }
      });
    });
    this.formData.labels = labels;
    this.dialogLabel = false;
  }
  //选择标签
  private changeLabel(index: number, indexValue: number) {
    if (!this.labelData[index].spec.multiSelect) {
      this.labelData[index].values.forEach((item, labelIndex) => {
        if (labelIndex !== indexValue) {
          item.checked = false;
        }
      });
    }
    this.labelData[index].values[indexValue].checked =
      !this.labelData[index].values[indexValue].checked;
  }
  //获取周几
  getWeek(index: number) {
    let week = '';
    if (index === 1) {
      week = '周一';
    } else if (index === 2) {
      week = '周二';
    } else if (index === 3) {
      week = '周三';
    } else if (index === 4) {
      week = '周四';
    } else if (index === 5) {
      week = '周五';
    } else if (index === 6) {
      week = '周六';
    } else if (index === 7) {
      week = '周天';
    }
    return week;
  }
  //选择接受预订
  private changeAcceptReserve(e: boolean) {
    if (!e) {
      this.formData.reserve.acceptCloseReserve = false;
    }
  }
  //选择非营业时间接受预订
  private changeAcceptCloseReserve(e: boolean) {
    if (e) {
      this.formData.reserve.acceptReserve = true;
    }
  }
  //当前时间减去10分钟
  reduceTime(time: string) {
    return moment(time, 'HH:mm').subtract(10, 'minutes').format('HH:mm');
  }
  //获取时间选择框背景色
  private getBackground(index: number, value: number) {
    let result = false;
    if (this.clickIndex === 0) {
      if (
        value <= this.formData.acceptDays[index].days.end &&
        value >= this.formData.acceptDays[index].days.begin
      ) {
        result = true;
      }
    } else {
      if (this.mouseVal) {
        if (this.booktime.begin <= Number(this.mouseVal)) {
          if (value <= this.mouseVal && value >= this.booktime.begin) {
            result = true;
          }
        } else {
          if (value >= this.mouseVal && value <= this.booktime.begin) {
            result = true;
          }
        }
      }
    }
    return result;
  }
  //显示选择时间弹框
  private selectPopUs(index: number) {
    this.timeIndex = index;
    this.clickIndex = 0;
    this.booktime = {
      begin: this.formData.acceptDays[index].days.begin,
      end: this.formData.acceptDays[index].days.end,
    };
  }
  //选择预订时间
  private selectBooktime(index: number, value: number) {
    if (this.clickIndex === 0) {
      this.booktime.begin = value;
      this.booktime.end = 100;
      this.clickIndex = 1;
    } else if (this.clickIndex === 1) {
      this.booktime.end = value;
      this.timeIndex = 1000;
      if (this.booktime.begin < this.booktime.end) {
        this.formData.acceptDays[index].days.begin = this.booktime.begin;
        this.formData.acceptDays[index].days.end = this.booktime.end;
      } else {
        this.formData.acceptDays[index].days.begin = this.booktime.end;
        this.formData.acceptDays[index].days.end = this.booktime.begin;
      }
      this.clickIndex = 0;
    }
  }
  //判断结束时间是否可选
  private endDisabled(item: {begin: number; end: number}, i: number) {
    return item.begin > i;
  }
  //移出接受预定天数
  private removeAcceptDays(index: number) {
    if (this.formData.acceptDays.length !== 1) {
      this.formData.acceptDays.splice(index, 1);
    }
  }
  //增加预定天数
  private addAcceptDays() {
    let num = 0;
    this.formData.acceptDays.forEach(item => {
      if (item.times.begin === null || item.times.end === null) {
        num++;
        this.$message.error('营业时间请填写完整');
      }
    });
    if (num === 0) {
      this.formData.acceptDays.push({
        times: {
          begin: '',
          end: '',
        },
        days: {
          begin: 0,
          end: 1,
        },
      });
    }
  }
  //移出营业时间
  private removeTime(index: number) {
    if (
      this.formData.serviceTime.times &&
      this.formData.serviceTime.times.length !== 1
    ) {
      this.formData.serviceTime.times.splice(index, 1);
    }
  }
  //增加营业时间
  private addTime() {
    let num = 0;
    if (this.formData.serviceTime.times) {
      this.formData.serviceTime.times.forEach(item => {
        if (item.begin === null || item.end === null) {
          num++;
          this.$message.error('营业时间请填写完整');
        }
      });
      if (num === 0) {
        this.formData.serviceTime.times.push({
          begin: '',
          end: '',
        });
      }
    }
  }
  //获取地图数据
  private getPosi(arr: [number, number]) {
    this.formData.geometry.coordinates = arr;
  }
  //修改
  private async submitForm() {
    (this.$refs.formData as Form).validate(async valid => {
      if (valid) {
        if (this.formData.geometry.coordinates[0] === 0) {
          this.$message.error('请选择店铺位置');
          return false;
        }
        //满减
        let reduceFlag = true;
        if (this.formData.fullReductions.length === 1) {
          if (
            this.formData.fullReductions[0].discount &&
            this.formData.fullReductions[0].condition
          ) {
            reduceFlag = true;
          } else if (
            !this.formData.fullReductions[0].discount &&
            !this.formData.fullReductions[0].condition
          ) {
            reduceFlag = true;
          } else {
            reduceFlag = false;
          }
        } else {
          this.formData.fullReductions.forEach(item => {
            if (
              item.discount === null ||
              item.condition === null ||
              item.discount.toString() === '' ||
              item.condition.toString() === '' ||
              item.promptDiscount !== '' ||
              item.promptCondition !== ''
            ) {
              reduceFlag = false;
            }
          });
        }
        const fullReductions = [] as Array<{
          condition: number;
          discount: number;
        }>;
        this.formData.fullReductions.forEach(item => {
          if (item.condition && item.discount) {
            fullReductions.push({
              condition: Math.round(Number(item.condition) * 100),
              discount: Math.round(Number(item.discount) * 100),
            });
          }
        });
        if (!reduceFlag) {
          this.$message.error('请把满减信息填写正确');
          return false;
        }

        //营业时间
        let num = 0;
        if (this.formData.serviceTime.times) {
          this.formData.serviceTime.times.forEach(item => {
            if (item.begin === null || item.end === null) {
              num++;
            }
          });
        }
        if (num !== 0) {
          this.$message.error('营业时间请填写完整');
          return false;
        }
        const acceptDays = [] as Array<ShopReserveDays>;
        this.formData.acceptDays.forEach(item => {
          acceptDays.push({
            times: item.times,
            days: {
              begin: item.days.begin,
              end: item.days.end,
            },
          });
        });
        if (Number(this.formData.tecServiceRate.rate) + this.serviceRate > 30) {
          this.$message.error(
            '技术服务费率和店铺应用配送服务费率之和不得大于30',
          );
          return;
        }

        try {
          this.submitFlag = true;
          if (this.role === '店铺') {
            const shopRequest = await shopRequests.create([
              {
                spec: {
                  logo: this.formData.logo,
                  phone: this.formData.phone,
                  serviceTime: this.formData.serviceTime,
                  address: this.formData.address,
                  description: this.formData.description,
                  geometry: this.formData.geometry,
                  auto: this.formData.auto,
                  ...(this.formData.reserve.acceptReserve
                    ? {
                        reserve: {
                          acceptReserve: this.formData.reserve.acceptReserve,
                          acceptCloseReserve:
                            this.formData.reserve.acceptCloseReserve,
                          acceptDays: acceptDays,
                          timeInterval: Number(this.formData.timeInterval),
                        },
                      }
                    : {
                        reserve: {
                          acceptReserve: this.formData.reserve.acceptReserve,
                        },
                      }),
                  stockUpTime: Number(this.formData.stockUpTime),
                  shopId: this.detailId,
                  applicationId: this.formData.applicationId,
                },
              },
            ]);
            if (shopRequest) {
              this.$message.success('保存成功');
              this.backBtn();
            }
          } else {
            const shop = await shops.update(
              filter =>
                filter(
                  f => f('_id'),
                  e => e.$eq(this.detailId),
                )(
                  f => f('spec')('applicationId'),
                  e =>
                    e.$eq(
                      ObjectId.createFromHexString(
                        this.$route.params.applicationId,
                      ),
                    ),
                ),
              update =>
                update.$set(s => {
                  s(
                    f => f('spec')('stockUpTime'),
                    Number(this.formData.stockUpTime),
                  )(f => f('spec')('score'), Number(this.formData.score))(
                    f => f('spec')('name'),
                    this.formData.name,
                  )(f => f('spec')('alipayShopId'), this.formData.alipayShopId)(
                    f => f('spec')('address'),
                    this.formData.address,
                  )(f => f('spec')('logo'), this.formData.logo)(
                    f => f('spec')('phone'),
                    this.formData.phone,
                  )(f => f('spec')('serviceTime'), this.formData.serviceTime)(
                    f => f('spec')('auto'),
                    this.formData.auto,
                  )(f => f('spec')('description'), this.formData.description)(
                    f => f('spec')('labels'),
                    this.formData.labels,
                  )(f => f('spec')('selfPick'), this.formData.selfPick)(
                    f => f('spec')('applicationId'),
                    this.formData.applicationId,
                  )(f => f('spec')('geometry'), this.formData.geometry)(
                    f => f('spec')('fullReductions'),
                    fullReductions,
                  )(f => f('spec')('tecServiceRate'), {
                    rate:
                      (Number(this.formData.tecServiceRate.rate) * 10000) / 100,
                    minimum: Math.round(
                      Number(this.formData.tecServiceRate.minimum) * 100,
                    ),
                  });
                  if (this.formData.merchantId) {
                    s(f => f('spec')('merchantId'), this.formData.merchantId);
                  }
                  if (this.formData.reserve.acceptReserve) {
                    s(f => f('spec')('reserve'), {
                      acceptReserve: this.formData.reserve.acceptReserve,
                      acceptCloseReserve:
                        this.formData.reserve.acceptCloseReserve,
                      acceptDays: acceptDays,
                      timeInterval: Number(this.formData.timeInterval),
                    });
                  } else {
                    s(f => f('spec')('reserve'), {
                      acceptReserve: this.formData.reserve.acceptReserve,
                    });
                  }
                  return s;
                }),
            );
            if (shop.length > 0) {
              this.$message.success('保存成功');
              this.backBtn();
            }
          }
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (e: any) {
          if (e.isAxiosError) {
            const axiosError = e as AxiosError;
            if (
              axiosError.response?.status === 500 &&
              axiosError.response.data.message.indexOf('E11000') > -1
            ) {
              this.$message.error('名称不能重复');
            }
          }
        } finally {
          this.submitFlag = false;
        }
      } else {
        return false;
      }
    });
  }
  //返回
  private backBtn() {
    this.$router.push({
      query: {
        menu: this.$route.query.menu,
        type: 'list',
      },
    });
  }
  //重置
  private resetForm() {
    this.formData.geometry.coordinates = [0, 0];
    this.formData.serviceTime.times = [
      {
        begin: '00:00',
        end: '24:00',
      },
    ];
    (this.$refs.formData as Form).resetFields();
  }
  //选择店铺logo
  private changeShopImage(id: string) {
    this.formData.logo = id;
  }
}
