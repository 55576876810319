var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('div',{staticClass:"value_box",style:({
      border: _vm.warnPrompt
        ? '1px solid #ff0000'
        : _vm.focusFlag
        ? '1px solid #11a983'
        : '1px solid #dcdfe6',
    })},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.focusFlag),expression:"!focusFlag"}],staticClass:"value_mask",on:{"mouseup":function($event){return _vm.upValue()}}}),_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.currentValue),expression:"currentValue",modifiers:{"trim":true}}],staticClass:"value_text",attrs:{"id":_vm.id,"type":"text"},domProps:{"value":(_vm.currentValue)},on:{"blur":[function($event){return _vm.changeVal()},function($event){return _vm.$forceUpdate()}],"input":function($event){if($event.target.composing)return;_vm.currentValue=$event.target.value.trim()}}}),_c('i',{staticClass:"el-icon-close value_close",on:{"click":function($event){return _vm.closeValue()}}})]),(_vm.warnPrompt)?_c('div',{staticClass:"warn_prompt"},[_vm._v("不超过15个字")]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }