
import html2canvas from 'html2canvas';
import vueQr from 'vue-qr';
import {Component, Vue} from 'vue-property-decorator';
import Pagination from '@/components/Pagination/index.vue';
import {
  shops,
  shopRequests,
  shopLabels,
  channels,
  wechatACodeRequests,
  alipayACodeRequests,
  wechatScenes,
  roleMappings,
  roles,
  users,
  roleDeleteRequests,
} from '../../../../../resources';
import moment from 'moment';
import {Shop} from '@/externals/MaxCI-Shop-v1';
import {ObjectId} from 'bson';
import {EJSON} from 'bson';
import {ShopLabel} from '@/externals/MaxCI-ShopLabel-v1';
import {Channel} from '@/externals/MaxCI-Channel-v1';
import {RoleMapping} from '@/externals/MaxCI-RoleMapping-v1';
import lodash from 'lodash';
import xlsx from 'xlsx';

interface ShopValue extends ShopLabel {
  values: Array<{
    name: string;
    checked: boolean;
  }>;
}
interface ManagerList extends RoleMapping {
  roleName: string;
  userName: string;
  phone: string;
}
@Component({
  name: 'shopList',
  components: {
    Pagination,
    vueQr,
  },
})
export default class extends Vue {
  private loading = false;
  private operateRoleData: null | ManagerList = null;
  private dialogRoleDel = false;
  private managerList = [] as Array<ManagerList>;
  private dialogManager = false;
  private codeType = '微信码';
  private deliveryMerchants: Array<string> = [];
  private codeLink = '';
  private codeDelivery = '';
  private codeRemark = '';
  private dialogNum = false;
  private imgLoad = false;
  private channelData = [] as Array<Channel>;
  private channelWechatData = [] as Array<Channel>;
  private channelAlipayData = [] as Array<Channel>;
  private ordinaryQrcode = '';
  private qrcode = '';
  private channelIndex = 0;
  private dialogCode = false;
  private dialogSearchLabel = false;
  private labelData: Array<ShopValue> = []; //设置标签数据
  private searchLabelData: Array<ShopValue> = []; //搜索标签数据
  private dialogService = false;
  private serviceTitle = '开业' as '开业' | '打烊';
  private operateId: ObjectId | null = null;
  private operateData: null | Shop = null;
  private dialogDel = false; //删除弹窗
  private searchData = {name: '', label: [] as Array<string>};
  private total = 0;
  private list: Array<Shop> = [];
  private listLoading = true;
  private listParams = {
    page: 1,
    limit: 10,
  };
  private applicationId = this.$route.params.applicationId;
  private role = localStorage.getItem('role');
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private shopAuthority: any = localStorage.getItem('shopAuthority')
    ? EJSON.parse(localStorage.getItem('shopAuthority') ?? '')
    : '';

  async created() {
    //回显搜索条件
    if (localStorage.getItem('searchData')) {
      this.searchData = JSON.parse(localStorage.getItem('searchData') ?? '');
      localStorage.setItem('searchData', '');
    }
    //回显分页
    if (localStorage.getItem('listParams')) {
      this.listParams = JSON.parse(localStorage.getItem('listParams') ?? '');
      localStorage.setItem('listParams', '');
    }
    this.updateList();
    //查询标签数据
    const list = (await shopLabels.find(stage =>
      stage.$match(match =>
        match(
          f => f('spec')('applicationId'),
          e => e.$eq(ObjectId.createFromHexString(this.applicationId)),
        ),
      ),
    )) as Array<ShopValue>;
    list.forEach(item => {
      item.values = [];
      if (item.values) {
        item.spec.values.forEach(valueItem => {
          item.values.push({
            name: valueItem,
            checked: false,
          });
        });
      }
    });
    this.labelData = list;
    this.searchLabelData = list;

    //查询微信渠道
    this.channelWechatData = await channels.find(stage =>
      stage.$match(match =>
        match(
          f => f('spec')('applicationId'),
          e => e.$eq(ObjectId.createFromHexString(this.applicationId)),
        )(
          f => f('spec')('type'),
          e => e.$eq('微信小程序'),
        )(
          f => f('status')('phase'),
          e => e.$eq('就绪'),
        ),
      ),
    );
    //查询支付宝渠道
    this.channelAlipayData = await channels.find(stage =>
      stage.$match(match =>
        match(
          f => f('spec')('applicationId'),
          e => e.$eq(ObjectId.createFromHexString(this.applicationId)),
        )(
          f => f('spec')('type'),
          e => e.$eq('支付宝小程序'),
        )(
          f => f('status')('phase'),
          e => e.$eq('就绪'),
        ),
      ),
    );
  }
  //删除店铺负责人保存
  private async delRoleSumbit() {
    if (this.operateRoleData) {
      const roleDeleteRequest = await roleDeleteRequests.create(
        [
          {
            spec: {
              type: this.operateRoleData.roleName as '店铺管理员' | '店铺财务',
              shopId: this.operateRoleData.spec.scopeId as ObjectId,
              userId: this.operateRoleData.spec.userId,
              applicationId: ObjectId.createFromHexString(this.applicationId),
            },
          },
        ],
        {
          watch: {
            filter: filter =>
              filter(
                f => f('operationType'),
                e => e.$eq('update'),
              )(
                f => f('fullDocument')('status')('phase'),
                e => e.$exists(true),
              ),
          },
          fullResource: true,
        },
      );
      if (roleDeleteRequest) {
        this.dialogRoleDel = false;
        this.$message.success('已删除');
        this.managerPopUps(this.operateId);
      }
    }
  }
  //删除店铺负责人
  private async delRolePopUps(row: ManagerList) {
    this.dialogRoleDel = true;
    this.operateRoleData = row;
  }
  //查询店铺负责人
  private async managerPopUps(id: ObjectId | null) {
    this.dialogManager = true;
    this.operateId = id;
    if (this.operateId) {
      const role = await roles.find(stage => stage);
      const list = await roleMappings.find(stage =>
        stage.$match(match =>
          match(
            f => f('spec')('applicationId'),
            e => e.$eq(ObjectId.createFromHexString(this.applicationId)),
          )(
            f => f('spec')('scopeId'),
            e => e.$eq(this.operateId as ObjectId),
          ),
        ),
      );
      let userIdData: Array<ObjectId> = [];
      list.forEach(item => {
        userIdData.push(item.spec.userId);
      });
      userIdData = Array.from(new Set(userIdData));
      const userData = await users.find(stage =>
        stage.$match(match =>
          match(
            f => f('_id'),
            e => e.$in(userIdData),
          )(
            f => f('spec')('applicationId'),
            e => e.$eq(ObjectId.createFromHexString(this.applicationId)),
          ),
        ),
      );
      const userInfoData = lodash.zipObject(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        userData.map(v => v._id.toHexString()) as any,
        userData,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      ) as any;
      this.managerList = list.map(v => {
        let roleName = '';
        for (let item of role) {
          if (v.spec.roleId.equals(item._id)) {
            roleName = item.spec.name;
          }
        }
        return {
          ...v,
          roleName: roleName,
          userName: userInfoData[v.spec.userId.toHexString()]
            ? userInfoData[v.spec.userId.toHexString()].spec.name
            : '',
          phone: userInfoData[v.spec.userId.toHexString()]
            ? userInfoData[v.spec.userId.toHexString()].spec.phone
            : '',
        };
      });
    }
  }
  //保存二维码
  private async saveImg() {
    const canvasId = this.$refs.html2canvas as HTMLElement;
    const canvas = await html2canvas(canvasId, {
      useCORS: true,
      scrollY: 0,
    });
    const url = canvas.toDataURL('image/jpeg');
    const a = document.createElement('a'); // 生成一个a元素
    const event = new MouseEvent('click'); // 创建一个单击事件
    a.download = '普通二维码'; // 设置图片名称
    a.href = url; // 将生成的URL设置为a.href属性
    a.dispatchEvent(event); // 触发a的单击事件
  }
  //切换店铺码类型
  private changeCodeType() {
    this.channelIndex = 0;
    this.codeRemark = '';
    this.codeDelivery = '';
    this.qrcode = '';
    this.ordinaryQrcode = '';
    if (this.codeType === '微信码') {
      this.channelData = this.channelWechatData;
    } else if (this.codeType === '支付宝码') {
      this.channelData = this.channelAlipayData;
    } else if (this.codeType === '普通二维码') {
      this.channelData = [...this.channelWechatData, ...this.channelAlipayData];
    }
    if (this.channelData.length > 0) {
      if (this.codeType === '微信码' || this.codeType === '支付宝码') {
        this.checkNumCode();
      }
    } else {
      this.$message.error('暂无可用渠道');
    }
  }
  //切换渠道
  private changeChannel(index: number) {
    this.channelIndex = index;
    this.qrcode = '';
    this.checkNumCode();
  }
  //下载店铺码
  private async downNumCode() {
    const a = document.createElement('a'); // 生成一个a元素
    const event = new MouseEvent('click'); // 创建一个单击事件
    a.download = this.codeRemark ? this.codeRemark : '店铺二维码'; // 设置图片名称
    a.href = this.qrcode; // 将生成的URL设置为a.href属性
    a.dispatchEvent(event); // 触发a的单击事件
  }
  //店铺码
  private shopCodePopUps(row: Shop) {
    this.operateData = row;
    this.operateId = row._id;
    this.deliveryMerchants = [];
    if (row.spec.selfPick) {
      this.deliveryMerchants.push('自取');
    }
    if (
      (row.spec.applicationDelivery && row.spec.applicationDelivery.enable) ||
      (row.spec.shopDelivery && row.spec.shopDelivery.enable)
    ) {
      this.deliveryMerchants.push('配送');
    }
    this.channelIndex = 0;
    this.codeLink = '';
    this.codeRemark = '';
    this.codeDelivery = '';
    this.qrcode = '';
    this.ordinaryQrcode = '';
    this.codeType = '微信码';
    this.channelData = this.channelWechatData;
    if (this.channelWechatData.length === 0) {
      this.channelData = this.channelAlipayData;
      this.codeType = '支付宝码';
    }
    if (this.channelData.length > 0) {
      this.checkNumCode();
      this.dialogNum = true;
    } else {
      this.$message.error('暂无可用渠道');
    }
  }
  //查询店铺码
  private async checkNumCode() {
    const params = EJSON.stringify({
      shopId: [this.operateId],
      type: this.codeDelivery,
      remark: this.codeRemark,
    });
    if (this.codeType === '普通二维码') {
      if (this.codeLink === '') {
        this.$message.warning('请填写二维码链接');
        return;
      }
      if (!/[a-zA-z]+:\/\/[^\s]*/.test(this.codeLink)) {
        this.$message.warning('二维码链接格式不正确');
        return;
      }
    }

    if (this.codeType === '微信码' || this.codeType === '普通二维码') {
      const link = new URL('wx://forward.self/page');
      link.searchParams.set('page', '店铺详情');
      link.searchParams.set('subpage', '店铺详情');
      link.searchParams.set('params', params);
      //const url = `wx://forward.self/page?page=店铺详情&subpage=店铺详情&params=${params}`;
      if (this.codeType === '微信码') {
        this.generatedWechatCode(link.href);
      } else if (this.codeType === '普通二维码') {
        this.generatedOrdinaryCode(link.href);
      }
    } else if (this.codeType === '支付宝码') {
      const link = new URL('wx://forward.self/page');
      link.searchParams.set('page', '店铺详情');
      link.searchParams.set('subpage', '店铺详情');
      link.searchParams.set('params', params);
      //const url = `page?page=店铺详情&subpage=店铺详情&params=${params}`;
      this.generatedAlipayCode(link.href);
    }
  }
  //生成普通二维码
  private async generatedOrdinaryCode(url: string) {
    try {
      this.imgLoad = true;
      const wechatScene = await wechatScenes.create([
        {
          spec: {
            url: url,
            channelId: this.channelData[0]._id,
            applicationId: ObjectId.createFromHexString(this.applicationId),
          },
        },
      ]);
      if (wechatScene.length > 0) {
        const link = new URL(this.codeLink);
        link.searchParams.set('scene', wechatScene[0]._id.toHexString());
        this.ordinaryQrcode = link.href;
      } else {
        this.$message.error('生成失败');
      }
    } catch (e) {
      console.log(e);
    } finally {
      this.imgLoad = false;
    }
  }
  //生成支付宝小程序码
  private async generatedAlipayCode(url: string) {
    try {
      this.imgLoad = true;
      const wechatScene = await wechatScenes.create([
        {
          spec: {
            url: url,
            channelId: this.channelData[this.channelIndex]._id,
            applicationId: ObjectId.createFromHexString(this.applicationId),
          },
        },
      ]);
      if (wechatScene.length > 0) {
        const link = new URL('wx://forward.self/page');
        link.searchParams.set('page', '首页');
        link.searchParams.set('subpage', '首页');
        link.searchParams.set('scene', wechatScene[0]._id.toHexString());
        const alipayACodeRequest = await alipayACodeRequests.create(
          [
            {
              spec: {
                urlParam: `page${link.search}`,
                queryParam: `page${link.search}`,
                describe: (this.operateData as Shop).spec.name,
                size: 'l',
                channelId: this.channelData[this.channelIndex]._id,
                applicationId: ObjectId.createFromHexString(this.applicationId),
              },
            },
          ],
          {
            watch: {
              filter: filter =>
                filter(
                  f => f('operationType'),
                  e => e.$eq('update'),
                )(
                  f => f('fullDocument')('status')('phase'),
                  e => e.$exists(true),
                ),
            },
            fullResource: true,
          },
        );
        if (alipayACodeRequest[0].status?.phase === '成功') {
          if (alipayACodeRequest[0].status?.qrCodeUrlCircleBlue) {
            this.getBase64(
              alipayACodeRequest[0].status?.qrCodeUrlCircleBlue,
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              (dataURL: any) => {
                this.qrcode = dataURL;
              },
            );
          }
        } else {
          this.$message.error(
            alipayACodeRequest[0].status?.conditions[0].message ?? '',
          );
        }
      }
    } catch (e) {
      console.log(e);
    } finally {
      this.imgLoad = false;
    }
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private getBase64(url: any, callback: any) {
    var Img = new Image(),
      dataURL = '';
    Img.src = url + '?v=' + Math.random();
    Img.setAttribute('crossOrigin', 'Anonymous');
    Img.onload = function () {
      var canvas = document.createElement('canvas') as HTMLCanvasElement,
        width = Img.width,
        height = Img.height;
      canvas.width = width;
      canvas.height = height;
      canvas.getContext('2d')?.drawImage(Img, 0, 0, width, height);
      dataURL = canvas.toDataURL('image/jpeg');
      return callback ? callback(dataURL) : null;
    };
  }
  //生成微信小程序码
  private async generatedWechatCode(url: string) {
    try {
      this.imgLoad = true;
      const wechatACodeRequest = await wechatACodeRequests.create(
        [
          {
            spec: {
              width: 1024,
              lineColor: '#000000',
              url: url,
              channelId: this.channelData[this.channelIndex]._id,
              isHyaline: true,
              applicationId: ObjectId.createFromHexString(this.applicationId),
            },
          },
        ],
        {
          watch: {
            filter: filter =>
              filter(
                f => f('operationType'),
                e => e.$eq('update'),
              )(
                f => f('fullDocument')('status')('phase'),
                e => e.$exists(true),
              ),
          },
          fullResource: true,
        },
      );
      if (wechatACodeRequest[0].status?.phase === '成功') {
        if (wechatACodeRequest[0].status?.qrcode) {
          const qrcode = wechatACodeRequest[0].status?.qrcode?.value(
            true,
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
          ) as any;
          const blob = new Blob([qrcode], {
            type: 'image/png',
          });
          const file = new FileReader();
          file.readAsDataURL(blob);
          file.onload = res => {
            this.qrcode = (res?.target?.result as string) ?? '';
          };
        }
      } else {
        this.$message.error(
          wechatACodeRequest[0].status?.conditions[0].message ?? '',
        );
      }
    } catch (e) {
      console.log(e);
    } finally {
      this.imgLoad = false;
    }
  }
  //重置搜索标签
  private resetLabel() {
    this.searchLabelData.forEach(item => {
      item.values.forEach(valItem => {
        valItem.checked = false;
      });
    });
    this.searchData.label = [];
    this.checkList();
  }
  //搜索选中标签
  private searchSelectLabel() {
    const labels = [] as Array<string>;
    this.searchLabelData.forEach(item => {
      item.values.forEach(valItem => {
        if (valItem.checked) {
          labels.push(item.spec.name + ':' + valItem.name);
        }
      });
    });
    this.searchData.label = labels;
    this.checkList();
  }
  //图片显示连接
  get downloadUrl() {
    return this.$store.state.pConfig.downloadUrl;
  }
  //新增
  private addBtn() {
    this.$router.push({
      query: {menu: this.$route.query.menu, type: 'add'},
    });
  }
  //跳转商品管理
  private toGoods(id: ObjectId) {
    localStorage.setItem('searchData', JSON.stringify(this.searchData));
    localStorage.setItem('listParams', JSON.stringify(this.listParams));
    this.$router.push(
      '/application/' +
        this.applicationId +
        '/shop/' +
        id.toHexString() +
        '/goods/index',
    );
  }
  //编辑
  private editBtn(id: ObjectId, type: string) {
    localStorage.setItem('searchData', JSON.stringify(this.searchData));
    localStorage.setItem('listParams', JSON.stringify(this.listParams));
    this.$router.push({
      query: {
        menu: this.$route.query.menu,
        type: type,
        id: id.toHexString(),
      },
    });
  }
  //处理标签数据
  private getLabel(label: string, type: string) {
    let value = '';
    if (type === 'name') {
      value = label.split(':')[0];
    } else {
      value = label.split(':')[1];
    }
    return value;
  }
  //转换时间
  getTime(time: string) {
    return moment(time).format('YYYY-MM-DD HH:mm:ss');
  }
  //获取图片
  private getImage(img: string) {
    return this.downloadUrl + img;
  }
  //搜索数据
  private checkList() {
    this.listParams.page = 1;
    this.updateList();
  }
  private async updateList() {
    this.listLoading = true;
    try {
      const list = await shops.find(stage =>
        stage
          .$match(match => {
            if (this.role === '店铺') {
              match(
                f => f('_id'),
                e => e.$in(this.shopAuthority),
              );
            }
            if (this.searchData.name) {
              match(
                f => f('spec')('name'),
                e => e.$regex(new RegExp(this.searchData.name)),
              );
            }
            if (this.searchData.label.length > 0) {
              match(
                f => f('spec')('labels'),
                e => e.$in(this.searchData.label),
              );
            }
            match(
              f => f('spec')('applicationId'),
              e => e.$eq(ObjectId.createFromHexString(this.applicationId)),
            );
            return match;
          })
          .$facet(facet =>
            facet('table', tableStage =>
              tableStage
                .$sort(sort =>
                  sort(f => f('spec')('score'), '降序')(
                    f => f('metadata')('creationTimestamp'),
                    '降序',
                  ),
                )
                .$skip((this.listParams.page - 1) * this.listParams.limit)
                .$limit(this.listParams.limit),
            )('count', countStage => countStage.$count('count')),
          ),
      );
      this.total = list[0].count[0] ? list[0].count[0].count.valueOf() : 0;
      this.list = list[0].table;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      this.$message.error('网络异常，请稍后重试');
    } finally {
      this.listLoading = false;
    }
  }
  //导出
  private async exportBtn() {
    this.loading = true;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const reportList: any = [];
    try {
      const count = (
        await shops.find(stage =>
          stage
            .$match(match => {
              if (this.role === '店铺') {
                match(
                  f => f('_id'),
                  e => e.$in(this.shopAuthority),
                );
              }
              if (this.searchData.name) {
                match(
                  f => f('spec')('name'),
                  e => e.$regex(new RegExp(this.searchData.name)),
                );
              }
              if (this.searchData.label.length > 0) {
                match(
                  f => f('spec')('labels'),
                  e => e.$in(this.searchData.label),
                );
              }
              match(
                f => f('spec')('applicationId'),
                e => e.$eq(ObjectId.createFromHexString(this.applicationId)),
              );
              return match;
            })
            .$facet(facet =>
              facet('count', countStage => countStage.$count('count')),
            ),
        )
      )[0].count[0].count.valueOf();
      reportList.push(['店铺名称', '手机号', '地址']);
      let i = 0;
      while (i < Math.ceil(count / 100)) {
        i++;
        const list = await shops.find(stage =>
          stage
            .$match(match => {
              if (this.role === '店铺') {
                match(
                  f => f('_id'),
                  e => e.$in(this.shopAuthority),
                );
              }
              if (this.searchData.name) {
                match(
                  f => f('spec')('name'),
                  e => e.$regex(new RegExp(this.searchData.name)),
                );
              }
              if (this.searchData.label.length > 0) {
                match(
                  f => f('spec')('labels'),
                  e => e.$in(this.searchData.label),
                );
              }
              match(
                f => f('spec')('applicationId'),
                e => e.$eq(ObjectId.createFromHexString(this.applicationId)),
              );
              return match;
            })
            .$facet(facet =>
              facet('table', tableStage =>
                tableStage
                  .$sort(sort =>
                    sort(f => f('spec')('score'), '降序')(
                      f => f('metadata')('creationTimestamp'),
                      '降序',
                    ),
                  )
                  .$skip((i - 1) * 100)
                  .$limit(100),
              ),
            ),
        );
        list[0].table.forEach(item => {
          reportList.push([item.spec.name, item.spec.phone, item.spec.address]);
        });
      }
      const filename = moment().format('YYYY-MM-DD') + '店铺.xlsx'; //文件名称
      const wsname = 'Sheet1'; //Excel第一个sheet的名称
      const wb = xlsx.utils.book_new(),
        ws = xlsx.utils.aoa_to_sheet(reportList);
      xlsx.utils.book_append_sheet(wb, ws, wsname); //将数据添加到工作薄
      xlsx.writeFile(wb, filename); //导出Excel
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      console.log(e);
      this.$message.error('网络异常，请稍后重试');
    } finally {
      this.loading = false;
    }
  }
  //开业 停业操作提示
  private servicePopUps(id: ObjectId, title: '开业' | '打烊') {
    this.operateId = id;
    this.dialogService = true;
    this.serviceTitle = title === '开业' ? '打烊' : '开业';
  }
  //开业 停业保存
  private serviceSumbit() {
    if (this.role === '店铺') {
      shopRequests
        .create([
          {
            spec: {
              service: this.serviceTitle,
              shopId: this.operateId as ObjectId,
              applicationId: ObjectId.createFromHexString(
                this.$route.params.applicationId,
              ),
            },
          },
        ])
        .then(res => {
          if (res.length > 0) {
            this.$message.success('保存成功');
            this.dialogService = false;
            this.updateList();
          } else {
            this.$message.error('保存失败');
          }
        });
    } else {
      shops
        .update(
          filter =>
            filter(
              f => f('_id'),
              e => e.$eq(this.operateId as ObjectId),
            )(
              f => f('spec')('applicationId'),
              e =>
                e.$eq(
                  ObjectId.createFromHexString(
                    this.$route.params.applicationId,
                  ),
                ),
            ),
          update =>
            update.$set(s => s(f => f('spec')('service'), this.serviceTitle)),
        )
        .then(res => {
          if (res.length > 0) {
            this.$message.success('保存成功');
            this.dialogService = false;
            this.updateList();
          }
        });
    }
  }
  //删除页面提示
  private delPopUps(id: ObjectId) {
    this.operateId = id;
    this.dialogDel = true;
  }
  //删除保存
  private async delSumbit() {
    try {
      if (this.operateId) {
        const shop = await shops.delete(filter =>
          filter(
            f => f('_id'),
            e => e.$eq(this.operateId as ObjectId),
          )(
            f => f('spec')('applicationId'),
            e => e.$eq(ObjectId.createFromHexString(this.applicationId)),
          ),
        );
        if (shop) {
          this.dialogDel = false;
          this.operateId = null;
          this.$message.success('已删除');
          this.updateList();
        }
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      this.$message.error('网络异常，请稍后重试');
    }
  }
}
