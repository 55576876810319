
import {Component, Vue} from 'vue-property-decorator';
import shopList from './list.vue';
import shopAdd from './add.vue';
import shopEdit from './edit.vue';
import shopDelivery from './delivery.vue';
@Component({
  name: 'shopIndex',
  components: {
    shopList,
    shopAdd,
    shopEdit,
    shopDelivery,
  },
})
export default class extends Vue {
  private type = '';
  created() {
    this.type = this.$route.query.type
      ? (this.$route.query.type as string)
      : 'list';
  }
}
