
import {Component, Vue, Prop} from 'vue-property-decorator';
import {Form} from 'element-ui';
import {deliveryMerchants, shops} from '../../../../resources';
import {ObjectId} from 'bson';
import {AxiosError} from 'axios';
import {Polygon, TimeRange} from '@/externals/MaxCI-DeliveryMerchant-v1';
import moment from 'moment';
import scopeMap from '@/components/scopeMap/index.vue';

@Component({
  name: 'deliveryMerchantsOperate',
  components: {
    scopeMap,
  },
})
export default class extends Vue {
  @Prop({required: false}) private detailId?: ObjectId;
  private formData = {
    type: '极创' as '极创' | '达达' | '顺丰同城', //商户类型
    name: '', //名称
    appId: '', //开发者ID
    appSecret: '', //开发者秘钥
    applicationId: ObjectId.createFromHexString(
      this.$route.params.applicationId,
    ),
    serviceScopes: [
      {
        scope: [] as [number, number][][],
        minimums: 0,
        deliveryFee: 0,
        times: [
          {
            begin: '00:00',
            end: '24:00',
          },
        ] as Array<TimeRange>,
      },
    ],
  }; //提交数据
  private rules = {
    type: [{required: true, message: '请选择商户类型', trigger: 'blur'}],
    name: [{required: true, message: '请输入名称', trigger: 'blur'}],
    appId: [{required: true, message: '请输入开发者ID', trigger: 'blur'}],
    appSecret: [{required: true, message: '请输入开发者秘钥', trigger: 'blur'}],
  }; //提交验证
  private submitFlag = false; //提交开关

  async created() {
    if (this.detailId) {
      try {
        const deliveryMerchant = (
          await deliveryMerchants.find(stage =>
            stage.$match(match =>
              match(
                f => f('_id'),
                e => e.$eq(this.detailId as ObjectId),
              )(
                f => f('spec')('applicationId'),
                e => e.$eq(this.formData.applicationId),
              ),
            ),
          )
        ).find(() => true);
        this.formData.type = deliveryMerchant?.spec.type ?? '极创';
        this.formData.name = deliveryMerchant?.spec.name ?? '';
        this.formData.appId = deliveryMerchant?.spec.appId ?? '';
        this.formData.appSecret = deliveryMerchant?.spec.appSecret ?? '';
        if (deliveryMerchant?.spec.serviceScopes) {
          this.formData.serviceScopes =
            deliveryMerchant?.spec.serviceScopes?.map(v => {
              return {
                times: v.times,
                minimums: v.minimums / 100,
                deliveryFee: v.deliveryFee / 100,
                scope: (v.scope as Polygon).coordinates,
              };
            });
        }
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (e: any) {
        this.$message.error('网络异常，请稍后重试');
      }
    }
  }
  //获取地图坐标范围数组
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private getScopePosi(arr: any, index: number) {
    if (arr.length !== 0) {
      const serviceScope = [] as [number, number][];
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      arr.forEach((item: any) => {
        if (item.lng) {
          serviceScope.push([item.lng, item.lat]);
        } else {
          serviceScope.push(item);
        }
      });
      serviceScope.push(serviceScope[0]);
      this.formData.serviceScopes[index].scope = [serviceScope];
    }
  }
  //当前时间减去10分钟
  reduceTime(time: string) {
    return moment(time, 'HH:mm').subtract(10, 'minutes').format('HH:mm');
  }
  //增加营业时间
  private addTime(index: number) {
    let num = 0;
    if (this.formData.serviceScopes[index].times) {
      this.formData.serviceScopes[index].times.forEach(item => {
        if (item.begin === null || item.end === null) {
          num++;
          this.$message.error('营业时间请填写完整');
        }
      });
      if (num === 0) {
        this.formData.serviceScopes[index].times.push({
          begin: '',
          end: '',
        });
      }
    }
  }
  //移出营业时间
  private removeTime(timeIndex: number, index: number) {
    if (
      this.formData.serviceScopes[index].times &&
      this.formData.serviceScopes[index].times.length !== 1
    ) {
      this.formData.serviceScopes[index].times.splice(timeIndex, 1);
    }
  }
  //选择营业时间的开始时间校验结束时间
  private changeTime(e: string, timeIndex: number, index: number) {
    if (
      this.formData.serviceScopes[index].times &&
      this.formData.serviceScopes[index].times[timeIndex]?.end
    ) {
      if (
        Number(e.split(':')[0]) >
          Number(
            this.formData.serviceScopes[index].times[timeIndex]?.end?.split(
              ':',
            )[0],
          ) ||
        (Number(e.split(':')[0]) ===
          Number(
            this.formData.serviceScopes[index].times[timeIndex]?.end?.split(
              ':',
            )[0],
          ) &&
          Number(e.split(':')[1]) >=
            Number(
              this.formData.serviceScopes[index].times[timeIndex]?.end?.split(
                ':',
              )[1],
            ))
      ) {
        this.formData.serviceScopes[index].times[timeIndex].end = '24:00';
      }
    }
  }
  //返回
  private backBtn() {
    this.$router.push({
      query: {
        menu: this.$route.query.menu,
        type: 'list',
        id: this.$route.query.id,
      },
    });
  }
  //提交
  private async submitForm() {
    (this.$refs.formData as Form).validate(async valid => {
      if (valid) {
        try {
          for (let item of this.formData.serviceScopes) {
            if (
              !/(^[1-9]\d*(\.\d{1,2})?$)|(^0(\.\d{1,2})?$)/.test(
                (item.minimums ?? '').toString(),
              )
            ) {
              this.$message.error('起送价格式不正确');
              return;
            }
            if (
              !/(^[1-9]\d*(\.\d{1,2})?$)|(^0(\.\d{1,2})?$)/.test(
                (item.deliveryFee ?? '').toString(),
              )
            ) {
              this.$message.error('配送费格式不正确');
              return;
            }
            if (item.times.length === 0) {
              this.$message.error('请选择配送时间');
              return;
            }
            if (item.scope.length === 0) {
              this.$message.error('请选择范围');
              return;
            }
          }
          const serviceScopes = this.formData.serviceScopes.map(v => {
            return {
              minimums: Math.round(Number(v.minimums) * 100),
              deliveryFee: Math.round(Number(v.deliveryFee) * 100),
              times: v.times,
              scope: {type: 'Polygon', coordinates: v.scope} as Polygon,
            };
          });
          this.submitFlag = true;
          if (this.detailId) {
            const deliveryMerchant = await deliveryMerchants.update(
              filter =>
                filter(
                  f => f('_id'),
                  e => e.$eq(this.detailId as ObjectId),
                )(
                  f => f('spec')('applicationId'),
                  e => e.$eq(this.formData.applicationId),
                ),
              update =>
                update.$set(s =>
                  s(f => f('spec')('type'), this.formData.type)(
                    f => f('spec')('name'),
                    this.formData.name,
                  )(f => f('spec')('appId'), this.formData.appId)(
                    f => f('spec')('appSecret'),
                    this.formData.appSecret,
                  )(f => f('spec')('serviceScopes'), serviceScopes),
                ),
            );
            if (deliveryMerchant.length > 0) {
              await shops.update(
                filter =>
                  filter(
                    f => f('spec')('applicationDelivery')('deliveryId'),
                    e => e.$eq(this.detailId as ObjectId),
                  )(
                    f => f('spec')('applicationId'),
                    e => e.$eq(this.formData.applicationId),
                  ),
                update =>
                  update.$set(s =>
                    s(
                      f =>
                        f('spec')('applicationDelivery')('serviceScopes')(0)(
                          'times',
                        ),
                      this.formData.serviceScopes[0].times,
                    )(
                      f =>
                        f('spec')('applicationDelivery')('serviceScopes')(0)(
                          'scope',
                        ),
                      {
                        type: 'Polygon',
                        coordinates: this.formData.serviceScopes[0].scope,
                      } as Polygon,
                    ),
                  ),
                {
                  watch: {
                    filter: filter =>
                      filter(
                        f => f('operationType'),
                        e => e.$eq('update'),
                      )(
                        f => f('fullDocument')('status')('phase'),
                        e => e.$exists(true),
                      ),
                  },
                  fullResource: true,
                },
              );
              this.$message.success('保存成功');
              this.backBtn();
            }
          } else {
            const deliveryMerchant = await deliveryMerchants.create([
              {
                spec: {
                  type: this.formData.type,
                  name: this.formData.name,
                  appId: this.formData.appId,
                  appSecret: this.formData.appSecret,
                  applicationId: this.formData.applicationId,
                  serviceScopes: serviceScopes,
                },
              },
            ]);
            if (deliveryMerchant.length > 0) {
              this.$message.success('保存成功');
              this.backBtn();
            }
          }
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (e: any) {
          if (e.isAxiosError) {
            const axiosError = e as AxiosError;
            if (
              axiosError.response?.status === 500 &&
              (axiosError.response.data.name === 'MongoError' ||
                axiosError.response.data.name === 'Error') &&
              axiosError.response.data.message.indexOf('E11000') > -1
            ) {
              this.$message.error('不能重复');
            }
          }
        } finally {
          this.submitFlag = false;
        }
      } else {
        return false;
      }
    });
  }
}
