
import {Component, Vue, Prop, Watch} from 'vue-property-decorator';
import Amap from 'vue-amap';
Vue.use(Amap);
Amap.initAMapApiLoader({
  key: 'd6dbe529c095ba542d60b26bf1059e13',
  plugin: [
    'AMap.Autocomplete',
    'AMap.PlaceSearch',
    'AMap.Scale',
    'AMap.OverView',
    'AMap.ToolBar',
    'AMap.MapType',
    'AMap.PolyEditor',
    'AMap.CircleEditor',
    'AMap.Geocoder',
    'AMap.Geolocation',
  ],
  v: '1.4.4',
});
// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare let AMap: any;
@Component({
  name: 'Map',
})
export default class extends Vue {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Prop() coordinates: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Prop() coordinatesPoint: any;
  @Prop() pointFlag: boolean; //是否选择点
  @Prop() scopeFlag: boolean; //是否选择范围
  @Prop() scopeIndex: number;
  private show = false;
  private searchOption = {
    city: '武汉',
    citylimit: false,
  };
  private map = {
    zoom: 12,
    center: [114.306412, 30.60344],
  };
  private markers = [[114.306412, 30.60344]];
  private lng = 0;
  private lat = 0;
  private events = {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    click: (e: any) => {
      if (this.pointFlag) {
        this.$emit('getLnglat', [e.lnglat.lng, e.lnglat.lat]);
      }
      this.changePosition(e.lnglat.lng, e.lnglat.lat);
    },
  };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private path = [] as any;
  private index = 0;
  plugin = {
    pName: 'Geolocation',
    events: {
      //eslint-disable-next-line @typescript-eslint/no-explicit-any
      click: (e: any) => {
        if (this.pointFlag) {
          this.$emit('getLnglat', [e.lnglat.lng, e.lnglat.lat]);
        }
        this.changePosition(e.lnglat.lng, e.lnglat.lat);
      },
      // this.path = [];
      // console.log(e.lnglat);
      // let lnglat = e.lnglat;
      // this.lnglatpoints.push({
      //   latitude: lnglat.lat,
      //   longitude: lnglat.lng,
      // });
      // console.log(this.lnglatpoints);
      // this.lnglatpoints.map((val: any, index: number) => {
      //   console.log(index);
      //   if (index === 0) {
      //     this.map.center = [val.longitude, val.latitude];
      //   }
      //   let arr = [val.longitude, val.latitude];
      //   this.path.push(arr);
      // });
    },
  };
  created() {
    if (this.coordinates) {
      this.setPolygons();
    }
  }
  @Watch('coordinates', {deep: true})
  onCoordinatesChange() {
    this.setPolygons();
    if (
      !this.coordinatesPoint &&
      this.coordinates &&
      this.coordinates.length > 0
    ) {
      this.setPoint(this.coordinates[0][0]);
    }
  }
  private setPolygons() {
    if (this.coordinates.length !== 0 && this.index === 0) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const polygons = [] as any;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      this.coordinates[0].forEach((item: any) => {
        if (item.lng) {
          polygons.push([item.lng, item.lat]);
        } else {
          polygons.push(item);
        }
      });
      this.path = polygons;
    }
  }
  @Watch('coordinatesPoint')
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onCoordinatesPointChange(val: any) {
    this.setPoint(val);
  }
  private setPoint(val: Array<number>) {
    if (val.length !== 0 && this.index === 0) {
      this.index++;
      this.map.zoom = 15;
      this.map.center = val;
      this.markers = [val];
      this.lng = val[0];
      this.lat = val[1];
    }
  }
  private drawPolygon() {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const vm = this as any;
    let map = vm.$refs.map.$$getInstance();
    // let map = new AMap.Map('map', {
    //   center: this.map.center,
    //   zoom: 15,
    // });
    // const marker = new AMap.Marker({
    //   position: vm.markers[0],
    //   offset: new AMap.Pixel(-21, -21), //偏移量，默认以marker左上角位置为基准点
    //   map: map,
    // });
    // map.add(marker);
    // // eslint-disable-next-line @typescript-eslint/no-explicit-any
    // map.on('click', function (e: any) {
    //   vm.markers = [[e.lnglat.lng, e.lnglat.lat]];
    //   vm.$emit('getLnglat', [e.lnglat.lng, e.lnglat.lat]);
    //   vm.changePosition(e.lnglat.lng, e.lnglat.lat);
    //   map.remove(marker);
    // });
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let polygonPath = [] as any;
    if (this.path.length > 0) {
      polygonPath = this.path;
    } else {
      for (let i = 0; i < 3; i++) {
        polygonPath.push(this.markers[0]);
      }
      this.path = polygonPath;
    }
    //使用 AMap.Polygon构建多边形
    // let polygon = new AMap.Polygon({
    //   path: polygonPath,
    //   strokeColor: '#ffba20',
    //   strokeWeight: 5,
    //   strokeOpacity: 0.8,
    //   fillOpacity: 0.5,
    //   fillColor: '#1791fc',
    //   zIndex: 100000,
    // });
    let polygon = vm.$refs.polygon.$$getInstance();
    //将多边形增加到地图上;
    // map.add(polygon);
    // 缩放地图到合适的视野级别
    // map.setFitView([polygon]);
    //构造折线编辑对象，并开启折线的编辑状态
    map.plugin(['AMap.PolyEditor'], function () {
      let polygonEditor = new AMap.PolyEditor(map, polygon);
      vm.polygonEditor = polygonEditor;
      polygonEditor.open();
      //关闭多边形编辑polygonEditor.close()触发该方法；
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      polygonEditor.on('end', function (event: any) {
        // event.target 即为编辑后的多边形对象,event.target.getPath()得到编辑完成后的点数组
        let pointArr = event.target.getPath();
        const path = [] as [number, number][];
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        pointArr.forEach((point: any, index: number) => {
          path.push([point.lng, point.lat]);
          if (index === 0) {
            vm.map.center = [point.lng, point.lat];
          }
        });
        vm.path = path;
        if (vm.scopeFlag) {
          if (vm.scopeIndex === undefined) {
            vm.$emit('getScopePosi', path);
          } else {
            vm.$emit('getScopePosi', path, vm.scopeIndex);
          }
        }
      });
    });
  }
  //点击地图切换坐标
  private changePosition(lng: number, lat: number) {
    this.markers = [[lng, lat]];
    this.map.center = [lng, lat];
    this.lng = lng;
    this.lat = lat;
  }
  //搜索地图
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private onSearchResult(pois: any) {
    if (pois.length > 0) {
      const center = pois[0];
      this.map.center = [center.lng, center.lat];
      this.markers = [[center.lng, center.lat]];
      this.lng = center.lng;
      this.lat = center.lat;
      if (this.pointFlag) {
        this.$emit('getLnglat', [center.lng, center.lat]);
      }
      this.path = [];
      for (let i = 0; i < 3; i++) {
        this.path.push(this.map.center);
      }
    }
  }
}
