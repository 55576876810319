
import {Component, Vue} from 'vue-property-decorator';
import {
  printers,
  applications,
  shops,
  printerRequests,
  printTemplates,
  printerDistributeRequests,
} from '../../../../resources';
import {ObjectId} from 'bson';
import {Printer} from '@/externals/MaxCI-Printer-v1';
import lodash from 'lodash';
import {
  PrinterSetLogoSpec,
  PrinterUpdateTemplateSpec,
} from '@/externals/MaxCI-PrinterRequest-v1';
import {Form} from 'element-ui';
import {Shop} from '@/externals/MaxCI-Shop-v1';
import Pagination from '@/components/Pagination/index.vue';
import uploadImage from '@/components/uploadImage/index.vue';
import {PrintTemplate} from '@/externals/MaxCI-PrintTemplate-v1';
@Component({
  name: 'printSetting',
  components: {
    Pagination,
    uploadImage,
  },
})
export default class extends Vue {
  private distributeShopId: Array<string> = [];
  private dialogDistribute = false;
  private printValue = '';
  private printData: Array<PrintTemplate> = [];
  private dialogTemplate = false;
  private loading = false;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private shopInfoData: any = {};
  private total = 0;
  private list: Array<Printer> = [];
  private listLoading = true;
  private listParams = {
    page: 1,
    limit: 50,
  };
  private applicationId = ObjectId.createFromHexString(
    this.$route.params.applicationId,
  );
  private searchData = {shopId: '', machineId: '', model: ''};
  private appLogo = '';
  private operateData: Printer | null = null;
  private dialogUnbind = false;
  private dialoglogo = false;
  private changeData = {logo: ''};
  private rules = {
    logo: [{required: true, message: '请选择图标', trigger: 'blur'}],
  };
  private action = '';
  private imgData = {};
  private shopSearchData = [] as Array<Shop>;
  async created() {
    const application = (
      await applications.find(stage =>
        stage.$match(match => {
          match(
            f => f('_id'),
            e =>
              e.$eq(
                ObjectId.createFromHexString(this.$route.params.applicationId),
              ),
          );
          return match;
        }),
      )
    ).find(() => true);
    this.appLogo = application?.spec.logo ?? '';

    //店铺搜索查询所有店铺
    const shopSearchData = [] as Array<Shop>;
    const shopCount = await shops.find(stage =>
      stage
        .$match(match => {
          match(
            f => f('spec')('applicationId'),
            e => e.$eq(this.applicationId),
          );
          return match;
        })
        .$facet(facet =>
          facet('count', countStage => countStage.$count('count')),
        ),
    );
    const count = shopCount[0].count[0]
      ? shopCount[0].count[0].count.valueOf()
      : 0;
    let i = 0;
    while (i < Math.ceil(count / 100)) {
      i++;
      const list = await shops.find(stage =>
        stage
          .$match(match => {
            match(
              f => f('spec')('applicationId'),
              e => e.$eq(this.applicationId),
            );
            return match;
          })
          .$facet(facet =>
            facet('table', tableStage =>
              tableStage
                .$sort(sort => sort(f => f('_id'), '降序'))
                .$skip((i - 1) * 100)
                .$limit(100),
            ),
          ),
      );
      shopSearchData.push(...list[0].table);
    }
    this.shopSearchData = shopSearchData;

    await this.updateList();
  }
  //更改模板保存
  private async templateSumbit() {
    let data = [] as Array<{spec: PrinterUpdateTemplateSpec}>;
    if (this.operateData) {
      data = [
        {
          spec: {
            type: '设置模板',
            templateId: ObjectId.createFromHexString(this.printValue),
            applicationId: this.applicationId,
            printerId: this.operateData._id,
          },
        },
      ];
    } else {
      this.list.forEach(item => {
        if (item.status?.phase === '就绪') {
          data.push({
            spec: {
              type: '设置模板',
              templateId: ObjectId.createFromHexString(this.printValue),
              applicationId: this.applicationId,
              printerId: item._id,
            },
          });
        }
      });
    }
    if (data.length > 0) {
      this.loading = true;
      const printerRequest = await printerRequests.create(data, {
        watch: {
          filter: filter =>
            filter(
              f => f('operationType'),
              e => e.$eq('update'),
            )(
              f => f('fullDocument')('status')('phase'),
              e => e.$exists(true),
            ),
        },
        fullResource: true,
      });
      if (printerRequest) {
        this.loading = false;
        if (printerRequest[0].status?.conditions[0].status) {
          this.$message.success('操作成功');
          this.updateList();
          this.dialogTemplate = false;
        } else {
          this.$message.error(
            printerRequest[0].status?.conditions[0].message ?? '',
          );
        }
      }
    } else {
      this.$message.error('暂无可修改机器');
    }
  }
  //更改模板弹窗
  private async templatePopUps() {
    this.printData = await printTemplates.find(stage =>
      stage.$match(match =>
        match(
          f => f('spec')('model'),
          e => e.$eq(this.searchData.model),
        ),
      ),
    );
    this.dialogTemplate = true;
  }
  //选择打印logo
  private changeUploadImage(id: string) {
    this.changeData.logo = id;
  }
  //更换图标保存
  private async logoSumbit() {
    (this.$refs.changeData as Form).validate(async valid => {
      if (valid) {
        try {
          let data = [] as Array<{spec: PrinterSetLogoSpec}>;
          if (this.operateData) {
            data = [
              {
                spec: {
                  type: '设置logo',
                  provider: '易联云',
                  applicationId: this.applicationId,
                  logo: this.changeData.logo,
                  printerId: this.operateData._id,
                },
              },
            ];
          } else {
            this.list.forEach(item => {
              if (
                item.status?.phase === '就绪' &&
                item.spec.provider === '易联云'
              ) {
                data.push({
                  spec: {
                    type: '设置logo',
                    provider: '易联云',
                    applicationId: this.applicationId,
                    logo: this.changeData.logo,
                    printerId: item._id,
                  },
                });
              }
            });
          }
          if (data.length > 0) {
            this.loading = true;
            const printerRequest = await printerRequests.create(data, {
              watch: {
                filter: filter =>
                  filter(
                    f => f('operationType'),
                    e => e.$eq('update'),
                  )(
                    f => f('fullDocument')('status')('phase'),
                    e => e.$exists(true),
                  ),
              },
              fullResource: true,
            });
            if (printerRequest) {
              this.loading = false;
              if (printerRequest[0].status?.conditions[0].status) {
                this.$message.success('操作成功');
                this.updateList();
                this.dialoglogo = false;
              } else {
                this.$message.error(
                  printerRequest[0].status?.conditions[0].message ?? '',
                );
              }
            }
          } else {
            this.$message.error('暂无可修改机器');
          }
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (e: any) {
          this.$message.error('网络异常，请稍后重试');
        } finally {
          this.loading = false;
        }
      } else {
        return false;
      }
    });
  }
  //更换图标弹窗
  private logoPopUps(item: Printer | null) {
    this.changeData.logo = '';
    this.operateData = item;
    if (this.operateData) {
      this.changeData.logo = this.operateData.spec.logo;
    }
    this.dialoglogo = true;
  }
  //分配店铺保存
  private distributeSumbit() {
    if (this.distributeShopId.length === 0) {
      this.$message.error('请选择店铺');
      return;
    }
    if (this.operateData) {
      const data = this.distributeShopId.map(v => {
        return {
          spec: {
            applicationId: this.applicationId,
            printerId: (this.operateData as Printer)._id,
            shopId: ObjectId.createFromHexString(v),
          },
        };
      });
      printerDistributeRequests
        .create(data, {
          watch: {
            filter: filter =>
              filter(
                f => f('operationType'),
                e => e.$eq('update'),
              )(
                f => f('fullDocument')('status')('phase'),
                e => e.$exists(true),
              ),
          },
          fullResource: true,
        })
        .then(res => {
          if (res) {
            this.$message.success('操作成功');
            this.updateList();
            this.dialogDistribute = false;
            // if (res[0].status?.conditions[0].status) {
            //   this.$message.success('操作成功');
            //   this.updateList();
            //   this.dialogDistribute = false;
            // } else {
            //   this.$message.error(res[0].status?.conditions[0].message ?? '');
            // }
          }
        });
    }
  }
  //分配店铺弹窗
  private distributePopUps(item: Printer) {
    this.distributeShopId = [];
    this.operateData = item;
    this.dialogDistribute = true;
  }
  //解绑弹窗
  private unbindPopUps(item: Printer) {
    this.operateData = item;
    this.dialogUnbind = true;
  }
  //解绑保存
  private unbindSumbit() {
    if (this.operateData) {
      printerRequests
        .create(
          [
            {
              spec: {
                type: '解绑',
                applicationId: this.applicationId,
                printerId: this.operateData._id,
                shopId: this.operateData.spec.shopId,
              },
            },
          ],
          {
            watch: {
              filter: filter =>
                filter(
                  f => f('operationType'),
                  e => e.$eq('update'),
                )(
                  f => f('fullDocument')('status')('phase'),
                  e => e.$exists(true),
                ),
            },
            fullResource: true,
          },
        )
        .then(res => {
          if (res) {
            if (res[0].status?.conditions[0].status) {
              this.$message.success('操作成功');
              this.updateList();
              this.dialogUnbind = false;
            } else {
              this.$message.error(res[0].status?.conditions[0].message ?? '');
            }
          }
        });
    }
  }
  //图片显示连接
  get downloadUrl() {
    return this.$store.state.pConfig.downloadUrl;
  }
  //搜索数据
  private checkList() {
    this.listParams.page = 1;
    this.updateList();
  }
  private async updateList() {
    try {
      const list = await printers.find(stage =>
        stage
          .$match(match => {
            match(
              f => f('spec')('applicationId'),
              e => e.$eq(this.applicationId),
            )(
              f => f('status')('phase'),
              e => e.$ne('删除'),
            );
            if (this.searchData.model) {
              match(
                f => f('spec')('model'),
                e => e.$eq(this.searchData.model),
              );
            }
            if (this.searchData.machineId) {
              match(
                f => f('spec')('machineId'),
                e => e.$eq(this.searchData.machineId),
              );
            }
            if (this.searchData.shopId) {
              match(
                f => f('spec')('shopId'),
                e =>
                  e.$eq(ObjectId.createFromHexString(this.searchData.shopId)),
              );
            }
            return match;
          })
          .$facet(facet =>
            facet('table', tableStage =>
              tableStage
                .$sort(sort =>
                  sort(f => f('metadata')('creationTimestamp'), '降序'),
                )
                .$skip((this.listParams.page - 1) * this.listParams.limit)
                .$limit(this.listParams.limit),
            )('count', countStage => countStage.$count('count')),
          ),
      );
      let shopIdData: Array<ObjectId> = [];
      list[0].table.forEach(item => {
        shopIdData.push(item.spec.shopId);
      });
      shopIdData = Array.from(new Set(shopIdData));
      const shopData = await shops.find(stage =>
        stage.$match(match =>
          match(
            f => f('_id'),
            e => e.$in(shopIdData),
          )(
            f => f('spec')('applicationId'),
            e => e.$eq(this.applicationId),
          ),
        ),
      );
      this.shopInfoData = lodash.zipObject(
        shopData.map(v => v._id.toHexString()),
        shopData,
      );
      this.total = list[0].count[0] ? list[0].count[0].count.valueOf() : 0;
      this.list = list[0].table;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      this.$message.error('网络异常，请稍后重试');
    } finally {
      this.listLoading = false;
    }
  }
}
