
import {Component, Vue, Watch} from 'vue-property-decorator';
import multiSelect from '@/components/multiSelect/index.vue';
import Pagination from '@/components/Pagination/index.vue';
import {
  shops,
  shopLabels,
  mallCustomers,
  mallCustomerRequests,
} from '../../../../resources';
import {ObjectId} from 'bson';
import {MallCustomer} from '@/externals/MaxCI-MallCustomer-v1';
import {Form} from 'element-ui';
import {ShopLabel} from '@/externals/MaxCI-ShopLabel-v1';
import {Shop} from '@/externals/MaxCI-Shop-v1';

interface ShopLabelValue extends ShopLabel {
  values: Array<{
    name: string;
    checked: boolean;
  }>;
}
@Component({
  name: 'sorterList',
  components: {
    multiSelect,
    Pagination,
  },
})
export default class extends Vue {
  private validPhone = (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    rule: any,
    value: string,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    callback: (error?: Error) => void,
  ) => {
    if (!/^1[23456789]\d{9}$/.test(value)) {
      callback(new Error('手机号格式不正确'));
    } else {
      callback();
    }
  };
  private formData = {
    name: '',
    phone: '',
  };
  private rules = {
    name: [{required: true, message: '请输入名称', trigger: 'blur'}],
    phone: [
      {required: true, message: '请输入手机号', trigger: 'blur'},
      {validator: this.validPhone, trigger: 'blur'},
    ],
  };
  private oldPhone = '';
  private submitFlag = false;
  private operateTitle = '';
  private dialogOperate = false;
  private shopIds = [] as Array<string>; //门店id
  private selectShopLabels: Array<string> = []; //选择的店铺标签
  private shopList: Array<Shop> = [];
  private allShopCheck = false; //门店是否全选
  private selectedShopShow = false; //查看预选中门店
  private labelData: Array<ShopLabelValue> = []; //搜索标签数据
  private dialogSelectShop = false;
  private reason = '';
  private operateId: null | ObjectId = null;
  private dialogReview = false; // 操作弹窗
  private title = '';
  private total = 0;
  private list: Array<MallCustomer> = [];
  private listLoading = true;
  private listParams = {
    page: 1,
    limit: 10,
  };
  private searchData = {name: '', phone: ''};
  private applicationId = ObjectId.createFromHexString(
    this.$route.params.applicationId,
  );
  async created() {
    this.updateList();
  }
  @Watch('dialogSelectShop')
  private changeDialogSelectShop() {
    if (!this.dialogSelectShop) {
      this.allShopCheck = false;
      this.selectedShopShow = false;
    }
  }
  @Watch('shopIds', {deep: true})
  private changeShopIds() {
    if (this.shopList.length === this.shopIds.length) {
      this.allShopCheck = true;
    } else {
      this.allShopCheck = false;
    }
  }
  //新增修改弹窗显示
  private operatePopUps(title: string, row: MallCustomer) {
    this.operateTitle = title;
    if (title === '修改') {
      this.operateId = row._id;
      this.formData.name = row.spec.name;
      this.formData.phone = row.spec.phone;
      this.oldPhone = row.spec.phone;
    } else {
      this.formData.name = '';
      this.formData.phone = '';
      this.operateId = null;
    }
    this.dialogOperate = true;
  }
  //新增修改提交
  private submitForm() {
    (this.$refs.formData as Form).validate(async valid => {
      if (valid) {
        try {
          if (this.operateTitle === '新增') {
            const mallCustomer = await mallCustomers.create([
              {
                spec: {
                  name: this.formData.name,
                  phone: this.formData.phone,
                  applicationId: this.applicationId,
                },
              },
            ]);
            if (mallCustomer.length > 0) {
              this.$message.success('新增成功');
              this.dialogOperate = false;
              this.updateList();
            }
          } else {
            const mallCustomer = await mallCustomers.update(
              filter =>
                filter(
                  f => f('_id'),
                  e => e.$eq(this.operateId as ObjectId),
                )(
                  f => f('spec')('applicationId'),
                  e => e.$eq(this.applicationId),
                ),
              update =>
                update.$set(s =>
                  s(f => f('spec')('name'), this.formData.name)(
                    f => f('spec')('phone'),
                    this.formData.phone,
                  ),
                ),
            );
            if (mallCustomer.length > 0) {
              const shop = await shops.update(
                filter =>
                  filter(
                    f => f('spec')('mallPhone'),
                    e => e.$eq(this.oldPhone),
                  )(
                    f => f('spec')('applicationId'),
                    e => e.$eq(this.applicationId),
                  ),
                update =>
                  update.$set(s => {
                    s(f => f('spec')('mallPhone'), this.formData.phone);
                    return s;
                  }),
              );
              if (shop.length > 0) {
                this.$message.success('修改成功');
                this.dialogOperate = false;
                this.updateList();
              }
            }
          }
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (e: any) {
          this.$message.error('网络异常，请稍后重试');
        } finally {
          this.submitFlag = false;
        }
      } else {
        return false;
      }
    });
  }
  //查看已选中门店
  private async checkSelectedShop() {
    this.selectShopLabels = [];
    this.labelData.forEach(item => {
      item.values.forEach(valItem => {
        valItem.checked = false;
      });
    });
    if (this.selectedShopShow) {
      const shopIds = this.shopIds.map(v => ObjectId.createFromHexString(v));
      try {
        const list = await shops.find(stage =>
          stage
            .$match(match => {
              match(
                f => f('spec')('applicationId'),
                e => e.$eq(this.applicationId),
              )(
                f => f('_id'),
                e => e.$in(shopIds),
              );
              return match;
            })
            .$facet(facet => facet('table', tableStage => tableStage)),
        );
        this.shopList = list[0].table;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (e: any) {
        console.log(e);
        this.$message.error('网络异常，请稍后重试');
      }
    } else {
      this.checkShop();
    }
  }
  //查询门店
  private async checkShop() {
    try {
      const list = await shops.find(stage =>
        stage
          .$match(match => {
            if (this.selectShopLabels.length > 0) {
              match(
                f => f('spec')('labels'),
                e => e.$in(this.selectShopLabels),
              );
            }
            match(
              f => f('spec')('applicationId'),
              e => e.$eq(this.applicationId),
            );
            return match;
          })
          .$facet(facet => facet('table', tableStage => tableStage)),
      );
      this.shopList = list[0].table;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      console.log(e);
      this.$message.error('网络异常，请稍后重试');
    }
  }
  //选择店铺标签
  private async selectLabel() {
    const labels = [] as Array<string>;
    this.labelData.forEach(item => {
      item.values.forEach(valItem => {
        if (valItem.checked) {
          labels.push(item.spec.name + ':' + valItem.name);
        }
      });
    });
    this.selectShopLabels = labels;
    await this.checkShop();
    //判断全选按钮是否选中
    const shopList = [] as Array<string>;
    this.shopList.forEach(item => {
      this.shopIds.forEach(shopItem => {
        if (item._id.equals(ObjectId.createFromHexString(shopItem))) {
          shopList.push(shopItem);
        }
      });
    });
    if (shopList.length === this.shopList.length) {
      this.allShopCheck = true;
    } else {
      this.allShopCheck = false;
    }
  }
  //全选
  private selectAllShop() {
    if (this.allShopCheck) {
      this.shopList.forEach(item => {
        if (this.shopIds.indexOf(item._id.toHexString()) === -1) {
          this.shopIds.push(item._id.toHexString());
        }
      });
    } else {
      const shopList = this.shopList.map(v => v._id.toHexString());
      const shopIds = shopList.filter(items => {
        if (!this.shopIds.includes(items)) return items;
      });
      this.shopIds = shopIds;
    }
  }
  //查询门店标签
  private async checkShopLabel() {
    //查询标签数据
    const list = (await shopLabels.find(stage =>
      stage.$match(match =>
        match(
          f => f('spec')('applicationId'),
          e => e.$eq(this.applicationId),
        ),
      ),
    )) as Array<ShopLabelValue>;
    list.forEach(item => {
      item.values = [];
      if (item.values) {
        item.spec.values.forEach(valueItem => {
          item.values.push({
            name: valueItem,
            checked: false,
          });
        });
      }
    });
    this.labelData = list;
  }
  //分配门店保存
  private submitSelectStore() {
    mallCustomerRequests
      .create(
        [
          {
            spec: {
              type: '分配客服',
              customerId: this.operateId as ObjectId,
              shopIds: this.shopIds.map(v => ObjectId.createFromHexString(v)),
              applicationId: this.applicationId,
            },
          },
        ],
        {
          watch: {
            filter: filter =>
              filter(
                f => f('operationType'),
                e => e.$eq('update'),
              )(
                f => f('fullDocument')('status')('phase'),
                e => e.$exists(true),
              ),
          },
          fullResource: true,
        },
      )
      .then(res => {
        if (res) {
          if (res[0].status?.conditions[0].status) {
            this.$message.success('分配成功');
            this.dialogSelectShop = false;
          } else {
            this.$message.error(res[0].status?.conditions[0].message ?? '');
          }
        }
      });
  }
  //分配门店弹窗
  private async selectPopUps(row: MallCustomer) {
    this.operateId = row._id;
    await this.checkShopLabel();
    await this.checkShop();
    const list = await shops.find(stage =>
      stage.$match(match => {
        match(
          f => f('spec')('applicationId'),
          e => e.$eq(this.applicationId),
        )(
          f => f('spec')('mallPhone'),
          e => e.$eq(row.spec.phone),
        );
        return match;
      }),
    );
    this.shopIds = list.map(v => v._id.toHexString());
    this.dialogSelectShop = true;
  }
  //图片显示连接
  get downloadUrl() {
    return this.$store.state.pConfig.downloadUrl;
  }
  //查看详情
  private toDetail(id: ObjectId) {
    this.$router.push({
      query: {
        menu: this.$route.query.menu,
        type: 'detail',
        id: id.toHexString(),
      },
    });
  }
  //搜索数据
  private checkList() {
    this.listParams.page = 1;
    this.updateList();
  }
  private async updateList() {
    this.listLoading = true;
    try {
      const list = await mallCustomers.find(stage =>
        stage
          .$match(match => {
            if (this.searchData.name) {
              match(
                f => f('spec')('name'),
                e => e.$regex(new RegExp(this.searchData.name)),
              );
            }
            if (this.searchData.phone) {
              match(
                f => f('spec')('phone'),
                e => e.$regex(new RegExp(this.searchData.phone)),
              );
            }
            match(
              f => f('spec')('applicationId'),
              e => e.$eq(this.applicationId),
            );
            return match;
          })
          .$facet(facet =>
            facet('table', tableStage =>
              tableStage
                .$sort(sort =>
                  sort(f => f('metadata')('creationTimestamp'), '降序'),
                )
                .$skip((this.listParams.page - 1) * this.listParams.limit)
                .$limit(this.listParams.limit),
            )('count', countStage => countStage.$count('count')),
          ),
      );
      this.total = list[0].count[0] ? list[0].count[0].count.valueOf() : 0;
      this.list = list[0].table;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      this.$message.error('网络异常，请稍后重试');
    } finally {
      this.listLoading = false;
    }
  }
}
