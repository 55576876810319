
import {Component, Vue, Prop} from 'vue-property-decorator';

@Component({
  name: 'multiSelect',
})
export default class extends Vue {
  @Prop()
  private list: Array<{name: string; num: string}>;
  private multiList: Array<{name: string; num: string}> = [];
  private selectedIndex = 0;
  private moreText = '更多';
  private moreShow = false;
  created() {
    this.multiList = this.list;
  }
  mounted() {
    //点击其他任意地方 更多弹窗消失
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    document.addEventListener('click', (e: any) => {
      if (document.getElementById('more')) {
        if (
          !(document.getElementById('more') as HTMLElement).contains(e.target)
        ) {
          this.moreShow = false;
        }
      }
    });
  }
  private change(index: number) {
    this.selectedIndex = index;
    if (index < 4) {
      this.$emit('changeTab', this.list[index].name);
    }
    if (index === 4) {
      this.moreShow = !this.moreShow;
    }
  }
  private changeMore(text: string) {
    this.moreText = text;
    this.$emit('changeTab', text);
  }
}
