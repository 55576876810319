
import {Component, Vue} from 'vue-property-decorator';
import mallBillingDetailsList from './list.vue';
import mallBillingDetailsInfo from './detail.vue';
@Component({
  name: 'mallBillingDetails',
  components: {
    mallBillingDetailsList,
    mallBillingDetailsInfo,
  },
})
export default class extends Vue {
  private type = '';
  created() {
    this.type = this.$route.query.type
      ? (this.$route.query.type as string)
      : 'list';
  }
}
