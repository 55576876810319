
import {Component, Vue} from 'vue-property-decorator';
import Pagination from '@/components/Pagination/index.vue';
import {
  payTransactions,
  payMerchants,
  shops,
  applications,
  shopLabels,
} from '../../../../../resources';
import {ObjectId} from 'bson';
import {PayMerchant} from '@/externals/MaxCI-PayMerchant-v1';
import moment from 'moment';
import xlsx from 'xlsx';
import {PayTransaction} from '@/externals/MaxCI-PayTransaction-v1';
import lodash from 'lodash';
import {StatisticsTemplate} from '@/externals/MaxCI-Application-v1';
import dot from 'dot';
import {EJSON} from 'bson';
import {ShopLabel} from '@/externals/MaxCI-ShopLabel-v1';
import {Shop} from '@/externals/MaxCI-Shop-v1';
import {getSheets, downloadExcel} from 'just-xlsx-export';

interface ShopValue extends ShopLabel {
  values: Array<{
    name: string;
    checked: boolean;
  }>;
}
interface IdPayMerchant extends PayMerchant {
  id: string;
}
interface DirectionSummary {
  _id: string;
  包装费: number;
  商品金额: number;
  应用优惠券金额: number;
  应用满减金额: number;
  应用金额: number;
  店铺优惠券金额: number;
  店铺满减金额: number;
  总计金额: number;
  用户金额: number;
  配送费: number;
  服务费: number;
}
interface SummaryStatistics extends StatisticsTemplate {
  num?: string;
}
@Component({
  name: 'mallBillingDetailsList',
  components: {
    Pagination,
  },
})
export default class extends Vue {
  private dialogSearchLabel = false;
  private labelData: Array<ShopValue> = []; //设置标签数据
  private searchLabelData: Array<ShopValue> = []; //搜索标签数据
  private summaryData: Array<SummaryStatistics> = [];
  private statistics: Array<SummaryStatistics> = [];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private shopInfoData: any = {};
  private loading = false;
  private payMerchant: Array<IdPayMerchant> = []; //微信商户
  private total = 0;
  private list: Array<PayTransaction> = [];
  private listLoading = true;
  private listParams = {
    page: 1,
    limit: 10,
  };
  private time = '-1';
  private searchData = {
    payMerchantId: '',
    payMerchantType: '' as '' | '微信',
    time: [
      moment({hour: 0}).subtract({day: 1}).toDate(),
      moment({hour: 0}).subtract({day: 1}).toDate(),
    ],
    id: '',
    orderId: '',
    shopId: '',
    label: [] as Array<string>,
  };
  private role = localStorage.getItem('role');
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private shopAuthority: any = localStorage.getItem('shopAuthority')
    ? EJSON.parse(localStorage.getItem('shopAuthority') ?? '')
    : '';
  private applicationId = ObjectId.createFromHexString(
    this.$route.params.applicationId,
  );
  private shopSearchData = [] as Array<Shop>;

  async created() {
    // const listaa = await payTransactions.find(stage =>
    //   stage
    //     .$set(set =>
    //       set('aaa', e =>
    //         e.$eq(
    //           e1 => e1.$fieldPath(f => f('spec')('amount')('total')),
    //           e2 =>
    //             e2.$add(
    //               e3 => e3.$fieldPath(f => f('spec')('amount')('user')),
    //               e4 => e4.$fieldPath(f => f('spec')('amount')('application')),
    //             ),
    //         ),
    //       ),
    //     )
    //     .$match(match =>
    //       match(
    //         f => f('aaa'),
    //         e => e.$eq(false),
    //       )(
    //         f => f('spec')('amount')('user'),
    //         e => e.$ne(1),
    //       ),
    //     ),
    // );
    //回显搜索条件
    if (localStorage.getItem('searchData')) {
      this.searchData = JSON.parse(localStorage.getItem('searchData') ?? '');
      localStorage.setItem('searchData', '');
    }
    //回显分页
    if (localStorage.getItem('listParams')) {
      this.listParams = JSON.parse(localStorage.getItem('listParams') ?? '');
      localStorage.setItem('listParams', '');
    }
    if (this.role === '应用') {
      //查询支付商户
      this.payMerchant = (
        await payMerchants.find(stage =>
          stage.$match(match =>
            match(
              f => f('spec')('applicationId'),
              e => e.$eq(this.applicationId),
            ),
          ),
        )
      ).map(v => {
        return {
          ...v,
          id: v._id.toHexString(),
        };
      });

      //店铺搜索查询所有店铺
      const shopSearchData = [] as Array<Shop>;
      const shopCount = await shops.find(stage =>
        stage
          .$match(match => {
            match(
              f => f('spec')('applicationId'),
              e => e.$eq(this.applicationId),
            );
            return match;
          })
          .$facet(facet =>
            facet('count', countStage => countStage.$count('count')),
          ),
      );
      const count = shopCount[0].count[0]
        ? shopCount[0].count[0].count.valueOf()
        : 0;
      let i = 0;
      while (i < Math.ceil(count / 100)) {
        i++;
        const list = await shops.find(stage =>
          stage
            .$match(match => {
              match(
                f => f('spec')('applicationId'),
                e => e.$eq(this.applicationId),
              );
              return match;
            })
            .$facet(facet =>
              facet('table', tableStage =>
                tableStage
                  .$sort(sort => sort(f => f('_id'), '降序'))
                  .$skip((i - 1) * 100)
                  .$limit(100),
              ),
            ),
        );
        shopSearchData.push(...list[0].table);
      }
      this.shopSearchData = shopSearchData;
    } else {
      //店铺搜索查询所有店铺
      this.shopSearchData = await shops.find(stage =>
        stage.$match(match => {
          match(
            f => f('spec')('applicationId'),
            e => e.$eq(this.applicationId),
          )(
            f => f('_id'),
            e => e.$in(this.shopAuthority),
          );
          return match;
        }),
      );
    }

    //查询要显示的汇总
    const application = (
      await applications.find(stage =>
        stage.$match(match => {
          match(
            f => f('_id'),
            e => e.$eq(this.applicationId),
          );
          return match;
        }),
      )
    ).find(() => true);
    this.statistics = application?.spec.serviceConfigs?.商城?.statistics ?? [];
    this.updateList();
    //查询标签数据
    const list = (await shopLabels.find(stage =>
      stage.$match(match =>
        match(
          f => f('spec')('applicationId'),
          e => e.$eq(this.applicationId),
        ),
      ),
    )) as Array<ShopValue>;
    list.forEach(item => {
      item.values = [];
      if (item.values) {
        item.spec.values.forEach(valueItem => {
          item.values.push({
            name: valueItem,
            checked: false,
          });
        });
      }
    });
    this.labelData = list;
    this.searchLabelData = list;
  }
  //重置搜索标签
  private resetLabel() {
    this.searchLabelData.forEach(item => {
      item.values.forEach(valItem => {
        valItem.checked = false;
      });
    });
    this.searchData.label = [];
    this.checkList();
  }
  private changeSelectLabel() {
    const labels = [] as Array<string>;
    this.searchLabelData.forEach(item => {
      item.values.forEach(valItem => {
        if (valItem.checked) {
          labels.push(item.spec.name + ':' + valItem.name);
        }
      });
    });
    this.searchData.label = labels;
  }
  //搜索选中标签
  private searchSelectLabel() {
    const labels = [] as Array<string>;
    this.searchLabelData.forEach(item => {
      item.values.forEach(valItem => {
        if (valItem.checked) {
          labels.push(item.spec.name + ':' + valItem.name);
        }
      });
    });
    this.searchData.label = labels;
    this.checkList();
  }
  //查看详情
  private to_detail(id: ObjectId) {
    localStorage.setItem('searchData', JSON.stringify(this.searchData));
    localStorage.setItem('listParams', JSON.stringify(this.listParams));
    this.$router.push({
      query: {
        menu: this.$route.query.menu,
        type: 'detail',
        id: id.toHexString(),
      },
    });
  }
  //获取店铺信息
  private getShopInfo(id: ObjectId) {
    return this.shopInfoData[id.toHexString()]
      ? this.shopInfoData[id.toHexString()].spec.name
      : '';
  }
  //转换时间
  getTime(time: string | Date) {
    return moment(time).format('YYYY-MM-DD HH:mm:ss');
  }
  //选择自定义日期
  private changeTimeFrame() {
    this.time = '';
    this.checkList();
  }
  //选择时间按钮
  private changeTime() {
    if (this.time === '-1') {
      this.searchData.time = [
        moment({hour: 0}).subtract({day: 1}).toDate(),
        moment({hour: 0}).subtract({day: 1}).toDate(),
      ];
    } else {
      this.searchData.time = [
        moment({hour: 0})
          .subtract({day: Number(this.time)})
          .toDate(),
        moment({hour: 0}).toDate(),
      ];
    }
    this.listParams.page = 1;
    this.updateList();
  }
  private removeReduce(num: number) {
    return Number(num.toString().replace('-', ''));
  }
  //查询列表
  private checkList() {
    this.listParams.page = 1;
    this.updateList();
  }
  //获取商户名称
  private getPayMerchantName(id: ObjectId) {
    let name = '';
    this.payMerchant.forEach(item => {
      if (item._id.equals(id)) {
        name = item?.spec.name;
      }
    });
    return name;
  }
  //查询列表
  private async updateList() {
    this.listLoading = true;
    try {
      //店铺搜索
      let searchShop: Array<ObjectId> = [];
      if (this.searchData.label.length > 0) {
        const shop = await shops.find(stage =>
          stage.$match(match => {
            match(
              f => f('spec')('applicationId'),
              e =>
                e.$eq(
                  ObjectId.createFromHexString(
                    this.$route.params.applicationId,
                  ),
                ),
            );
            if (this.searchData.shopId) {
              match(
                f => f('_id'),
                e =>
                  e.$eq(ObjectId.createFromHexString(this.searchData.shopId)),
              );
            } else {
              if (this.role === '店铺' || this.role === '财务') {
                match(
                  f => f('_id'),
                  e => e.$in(this.shopAuthority),
                );
              }
            }

            if (this.searchData.label.length > 0) {
              match(
                f => f('spec')('labels'),
                e => e.$in(this.searchData.label),
              );
            }
            return match;
          }),
        );
        searchShop = shop.map(v => v._id);
      } else {
        if (this.searchData.shopId) {
          searchShop = [ObjectId.createFromHexString(this.searchData.shopId)];
        } else {
          searchShop = this.shopAuthority;
        }
      }
      if (this.searchData.label.length > 0 && searchShop.length === 0) {
        this.summaryData = [];
        this.total = 0;
        this.list = [];
      } else {
        const list = await payTransactions.find(stage =>
          stage
            .$match(match =>
              match.$and(and => {
                and(query => {
                  query(
                    f => f('spec')('applicationId'),
                    e =>
                      e.$eq(
                        ObjectId.createFromHexString(
                          this.$route.params.applicationId,
                        ),
                      ),
                  )(
                    f => f('spec')('orderType'),
                    e => e.$eq('商城'),
                  );
                  if (searchShop.length > 0) {
                    query(
                      f => f('spec')('shopId'),
                      e => e.$in(searchShop),
                    );
                  }
                  if (this.searchData.payMerchantId) {
                    query(
                      f => f('spec')('payMerchantId'),
                      e =>
                        e.$eq(
                          ObjectId.createFromHexString(
                            this.searchData.payMerchantId,
                          ),
                        ),
                    );
                  }
                  if (this.searchData.payMerchantType) {
                    query(
                      f => f('spec')('payMerchantType'),
                      e => e.$eq(this.searchData.payMerchantType as '微信'),
                    );
                  }
                  if (this.searchData.id) {
                    query(
                      f => f('_id'),
                      e =>
                        e.$eq(ObjectId.createFromHexString(this.searchData.id)),
                    );
                  }
                  if (this.searchData.orderId) {
                    query(
                      f => f('spec')('orderId'),
                      e =>
                        e.$eq(
                          ObjectId.createFromHexString(this.searchData.orderId),
                        ),
                    );
                  }
                  return query;
                });
                if (this.searchData.time && this.searchData.time.length > 0) {
                  and(query =>
                    query(
                      f => f('metadata')('updationTimestamp'),
                      e => e.$gte(moment(this.searchData.time[0]).toDate()),
                    ),
                  );
                  and(query =>
                    query(
                      f => f('metadata')('updationTimestamp'),
                      e =>
                        e.$lte(
                          moment(this.searchData.time[1])
                            .add({day: 1})
                            .subtract({millisecond: 1})
                            .toDate(),
                        ),
                    ),
                  );
                }
                return and;
              }),
            )
            .$facet(facet =>
              facet('table', tableStage =>
                tableStage
                  .$sort(sort =>
                    sort(f => f('metadata')('updationTimestamp'), '降序'),
                  )
                  .$skip((this.listParams.page - 1) * this.listParams.limit)
                  .$limit(this.listParams.limit),
              )('count', countStage => countStage.$count('count'))(
                'summary',
                summaryStage =>
                  summaryStage.$group(
                    e => e.$fieldPath(f => f('spec')('direction')),
                    group =>
                      group('包装费', e =>
                        e.$sum(sum =>
                          sum.$fieldPath(f => f('spec')('amount')('packing')),
                        ),
                      )('配送费', e =>
                        e.$sum(sum =>
                          sum.$fieldPath(f => f('spec')('amount')('delivery')),
                        ),
                      )('服务费', e =>
                        e.$sum(sum =>
                          sum.$fieldPath(f => f('spec')('amount')('service')),
                        ),
                      )('商品金额', e =>
                        e.$sum(sum =>
                          sum.$fieldPath(f => f('spec')('amount')('product')),
                        ),
                      )('总计金额', e =>
                        e.$sum(sum =>
                          sum.$fieldPath(f => f('spec')('amount')('total')),
                        ),
                      )('用户金额', e =>
                        e.$sum(sum =>
                          sum.$fieldPath(f => f('spec')('amount')('user')),
                        ),
                      )('应用金额', e =>
                        e.$sum(sum =>
                          sum.$fieldPath(f =>
                            f('spec')('amount')('application'),
                          ),
                        ),
                      )('店铺满减金额', e =>
                        e.$sum(sum =>
                          sum.$fieldPath(f =>
                            f('spec')('amount')('fullReduction')('shop'),
                          ),
                        ),
                      )('应用满减金额', e =>
                        e.$sum(sum =>
                          sum.$fieldPath(f =>
                            f('spec')('amount')('fullReduction')('application'),
                          ),
                        ),
                      )('店铺优惠券金额', e =>
                        e.$sum(sum =>
                          sum.$fieldPath(f =>
                            f('spec')('amount')('coupon')('shop'),
                          ),
                        ),
                      )('应用优惠券金额', e =>
                        e.$sum(sum =>
                          sum.$fieldPath(f =>
                            f('spec')('amount')('coupon')('application'),
                          ),
                        ),
                      ),
                  ),
              ),
            ),
        );
        let shopIdData: Array<ObjectId> = [];
        list[0].table.forEach(item => {
          if (item.spec.orderType === '商城' && item.spec.shopId) {
            shopIdData.push(item.spec.shopId);
          }
        });
        shopIdData = Array.from(new Set(shopIdData));
        const shopData = await shops.find(stage =>
          stage.$match(match =>
            match(
              f => f('_id'),
              e => e.$in(shopIdData),
            )(
              f => f('spec')('applicationId'),
              e =>
                e.$eq(
                  ObjectId.createFromHexString(
                    this.$route.params.applicationId,
                  ),
                ),
            ),
          ),
        );
        this.shopInfoData = lodash.zipObject(
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          shopData.map(v => v._id.toHexString()) as any,
          shopData,
        );
        this.total = list[0].count[0] ? list[0].count[0].count.valueOf() : 0;
        this.list = list[0].table;

        this.summaryData = [];
        let income = null as null | DirectionSummary;
        let expenditure = null as null | DirectionSummary;
        list[0].summary.forEach(item => {
          if (item._id === '收入') {
            income = item;
          } else if (item._id === '支出') {
            expenditure = item;
          }
        });
        if (!expenditure) {
          expenditure = {
            包装费: 0,
            商品金额: 0,
            应用优惠券金额: 0,
            应用满减金额: 0,
            应用金额: 0,
            店铺优惠券金额: 0,
            店铺满减金额: 0,
            总计金额: 0,
            用户金额: 0,
            配送费: 0,
            服务费: 0,
            _id: '支出',
          };
        }
        if (!income) {
          income = {
            包装费: 0,
            商品金额: 0,
            应用优惠券金额: 0,
            应用满减金额: 0,
            应用金额: 0,
            店铺优惠券金额: 0,
            店铺满减金额: 0,
            总计金额: 0,
            用户金额: 0,
            配送费: 0,
            服务费: 0,
            _id: '收入',
          };
        }
        if (list[0].summary.length > 0) {
          this.statistics.forEach(item => {
            const template = dot.template(`{{
          const 收入 = it.收入;
          const 支出 = it.支出;
          }}{{=((${item.tepmlate})/100).toFixed(2)}}`);
            const tem = template({
              收入: income,
              支出: expenditure,
            });
            this.summaryData.push({
              ...item,
              num: tem,
            });
          });
        }
      }

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      this.$message.error('网络异常，请稍后重试');
    } finally {
      this.listLoading = false;
    }
  }
  //导出汇总
  private async exportSummary() {
    this.loading = true;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const reportList: any = [];
    try {
      //店铺搜索
      let searchShop: Array<ObjectId> = [];
      if (this.searchData.label.length > 0) {
        const shop = await shops.find(stage =>
          stage.$match(match => {
            match(
              f => f('spec')('applicationId'),
              e =>
                e.$eq(
                  ObjectId.createFromHexString(
                    this.$route.params.applicationId,
                  ),
                ),
            );
            if (this.searchData.shopId) {
              match(
                f => f('_id'),
                e =>
                  e.$eq(ObjectId.createFromHexString(this.searchData.shopId)),
              );
            } else {
              if (this.role === '店铺' || this.role === '财务') {
                match(
                  f => f('_id'),
                  e => e.$in(this.shopAuthority),
                );
              }
            }

            if (this.searchData.label.length > 0) {
              match(
                f => f('spec')('labels'),
                e => e.$in(this.searchData.label),
              );
            }
            return match;
          }),
        );
        searchShop = shop.map(v => v._id);
      } else {
        if (this.searchData.shopId) {
          searchShop = [ObjectId.createFromHexString(this.searchData.shopId)];
        } else {
          searchShop = this.shopAuthority;
        }
      }
      if (this.searchData.label.length > 0 && searchShop.length === 0) {
        this.$message.warning('暂无数据');
      } else {
        const payTransactionCount = await payTransactions.find(stage =>
          stage
            .$match(match =>
              match.$and(and => {
                and(query => {
                  if (searchShop.length > 0) {
                    query(
                      f => f('spec')('shopId'),
                      e => e.$in(searchShop),
                    );
                  }
                  query(
                    f => f('spec')('applicationId'),
                    e =>
                      e.$eq(
                        ObjectId.createFromHexString(
                          this.$route.params.applicationId,
                        ),
                      ),
                  )(
                    f => f('spec')('orderType'),
                    e => e.$eq('商城'),
                  );
                  if (this.searchData.payMerchantId) {
                    query(
                      f => f('spec')('payMerchantId'),
                      e =>
                        e.$eq(
                          ObjectId.createFromHexString(
                            this.searchData.payMerchantId,
                          ),
                        ),
                    );
                  }
                  if (this.searchData.payMerchantType) {
                    query(
                      f => f('spec')('payMerchantType'),
                      e => e.$eq(this.searchData.payMerchantType as '微信'),
                    );
                  }
                  if (this.searchData.id) {
                    query(
                      f => f('_id'),
                      e =>
                        e.$eq(ObjectId.createFromHexString(this.searchData.id)),
                    );
                  }
                  if (this.searchData.orderId) {
                    query(
                      f => f('spec')('orderId'),
                      e =>
                        e.$eq(
                          ObjectId.createFromHexString(this.searchData.orderId),
                        ),
                    );
                  }
                  return query;
                });
                if (this.searchData.time && this.searchData.time.length > 0) {
                  and(query =>
                    query(
                      f => f('metadata')('updationTimestamp'),
                      e => e.$gte(moment(this.searchData.time[0]).toDate()),
                    ),
                  );
                  and(query =>
                    query(
                      f => f('metadata')('updationTimestamp'),
                      e =>
                        e.$lte(
                          moment(this.searchData.time[1])
                            .add({day: 1})
                            .subtract({millisecond: 1})
                            .toDate(),
                        ),
                    ),
                  );
                }
                return and;
              }),
            )
            .$facet(facet =>
              facet('count', countStage =>
                countStage
                  .$group(e => e.$fieldPath(f => f('spec')('shopId')))
                  .$count('count'),
              ),
            ),
        );
        const count = payTransactionCount[0].count[0]
          ? payTransactionCount[0].count[0].count.valueOf()
          : 0;
        if (count === 0) {
          this.$message.warning('暂无数据');
        } else {
          reportList.push([
            `${moment(this.searchData.time[0]).format('YYYY-MM-DD')}-${moment(
              this.searchData.time[1],
            ).format('YYYY-MM-DD')}账单汇总`,
          ]);
          reportList.push([]);
          reportList.push([
            '店铺名称',
            '收支(￥)',
            '商品金额(￥)',
            '商品成本价(￥)',
            '包装费(￥)',
            '配送费(￥)',
            '服务费(￥)',
            '店铺满减(￥)',
            '平台满减(￥)',
            '店铺优惠(￥)',
            '平台优惠(￥)',
          ]);

          let i = 0;
          while (i < Math.ceil(count / 100)) {
            i++;
            const list = await payTransactions.find(stage =>
              stage
                .$match(match =>
                  match.$and(and => {
                    and(query => {
                      query(
                        f => f('spec')('applicationId'),
                        e =>
                          e.$eq(
                            ObjectId.createFromHexString(
                              this.$route.params.applicationId,
                            ),
                          ),
                      )(
                        f => f('spec')('orderType'),
                        e => e.$eq('商城'),
                      );
                      if (searchShop.length > 0) {
                        query(
                          f => f('spec')('shopId'),
                          e => e.$in(searchShop),
                        );
                      }
                      if (this.searchData.payMerchantId) {
                        query(
                          f => f('spec')('payMerchantId'),
                          e =>
                            e.$eq(
                              ObjectId.createFromHexString(
                                this.searchData.payMerchantId,
                              ),
                            ),
                        );
                      }
                      if (this.searchData.payMerchantType) {
                        query(
                          f => f('spec')('payMerchantType'),
                          e => e.$eq(this.searchData.payMerchantType as '微信'),
                        );
                      }
                      if (this.searchData.id) {
                        query(
                          f => f('_id'),
                          e =>
                            e.$eq(
                              ObjectId.createFromHexString(this.searchData.id),
                            ),
                        );
                      }
                      if (this.searchData.orderId) {
                        query(
                          f => f('spec')('orderId'),
                          e =>
                            e.$eq(
                              ObjectId.createFromHexString(
                                this.searchData.orderId,
                              ),
                            ),
                        );
                      }
                      return query;
                    });
                    if (
                      this.searchData.time &&
                      this.searchData.time.length > 0
                    ) {
                      and(query =>
                        query(
                          f => f('metadata')('updationTimestamp'),
                          e => e.$gte(moment(this.searchData.time[0]).toDate()),
                        ),
                      );
                      and(query =>
                        query(
                          f => f('metadata')('updationTimestamp'),
                          e =>
                            e.$lte(
                              moment(this.searchData.time[1])
                                .add({day: 1})
                                .subtract({millisecond: 1})
                                .toDate(),
                            ),
                        ),
                      );
                    }
                    return and;
                  }),
                )
                .$facet(facet =>
                  facet('table', tableStage =>
                    tableStage
                      .$group(
                        e => e.$fieldPath(f => f('spec')('shopId')),
                        group =>
                          group('user', e =>
                            e.$sum(sum =>
                              sum.$fieldPath(f => f('spec')('amount')('user')),
                            ),
                          )('product', e =>
                            e.$sum(sum =>
                              sum.$fieldPath(f =>
                                f('spec')('amount')('product'),
                              ),
                            ),
                          )('productCost', e =>
                            e.$sum(sum =>
                              sum.$fieldPath(f =>
                                f('spec')('amount')('productCost'),
                              ),
                            ),
                          )('packing', e =>
                            e.$sum(sum =>
                              sum.$fieldPath(f =>
                                f('spec')('amount')('packing'),
                              ),
                            ),
                          )('delivery', e =>
                            e.$sum(sum =>
                              sum.$fieldPath(f =>
                                f('spec')('amount')('delivery'),
                              ),
                            ),
                          )('service', e =>
                            e.$sum(sum =>
                              sum.$fieldPath(f =>
                                f('spec')('amount')('service'),
                              ),
                            ),
                          )('fullReductionShop', e =>
                            e.$sum(sum =>
                              sum.$fieldPath(f =>
                                f('spec')('amount')('fullReduction')('shop'),
                              ),
                            ),
                          )('fullReductionApplication', e =>
                            e.$sum(sum =>
                              sum.$fieldPath(f =>
                                f('spec')('amount')('fullReduction')(
                                  'application',
                                ),
                              ),
                            ),
                          )('couponShop', e =>
                            e.$sum(sum =>
                              sum.$fieldPath(f =>
                                f('spec')('amount')('coupon')('shop'),
                              ),
                            ),
                          )('couponApplication', e =>
                            e.$sum(sum =>
                              sum.$fieldPath(f =>
                                f('spec')('amount')('coupon')('application'),
                              ),
                            ),
                          ),
                      )
                      .$sort(sort => sort(f => f('_id'), '降序'))
                      .$skip((i - 1) * 100)
                      .$limit(100),
                  ),
                ),
            );
            let shopIdData: Array<ObjectId> = [];
            list[0].table.forEach(item => {
              shopIdData.push(item._id);
            });
            shopIdData = Array.from(new Set(shopIdData));
            const shopData = await shops.find(stage =>
              stage.$match(match =>
                match(
                  f => f('_id'),
                  e => e.$in(shopIdData),
                )(
                  f => f('spec')('applicationId'),
                  e =>
                    e.$eq(
                      ObjectId.createFromHexString(
                        this.$route.params.applicationId,
                      ),
                    ),
                ),
              ),
            );
            const shopInfoData = lodash.zipObject(
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              shopData.map(v => v._id.toHexString()) as any,
              shopData,
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
            ) as any;
            list[0].table.forEach(item => {
              reportList.push([
                shopInfoData[item._id.toHexString()]
                  ? shopInfoData[item._id.toHexString()].spec.name
                  : '',
                Number((item.user / 100).toFixed(2)),
                Number((item.product / 100).toFixed(2)),
                Number((item.productCost / 100).toFixed(2)),
                Number((item.packing / 100).toFixed(2)),
                Number((item.delivery / 100).toFixed(2)),
                Number((item.service / 100).toFixed(2)),
                Number((item.fullReductionShop / 100).toFixed(2)),
                Number((item.fullReductionApplication / 100).toFixed(2)),
                Number((item.couponShop / 100).toFixed(2)),
                Number((item.couponApplication / 100).toFixed(2)),
              ]);
            });
          }
          const filename = moment().format('YYYY-MM-DD') + '商城店铺账单汇总'; //文件名称
          const ws = getSheets(reportList);
          ws['!merges'] = [{s: {r: 0, c: 0}, e: {r: 1, c: 9}}];
          ws['!rows'] = [{hpx: 30}];
          ws['A1'].s = {
            alignment: {horizontal: 'center', vertical: 'center'},
            font: {sz: 18, bold: true},
          };
          downloadExcel(ws, filename);
          // const wsname = 'Sheet1'; //Excel第一个sheet的名称
          // const wb = xlsx.utils.book_new(),
          //   ws = xlsx.utils.json_to_sheet(reportList);
          // ws['!merges'] = [{s: {r: 0, c: 0}, e: {r: 0, c: 9}}];
          // ws['!rows'] = [{hpx: 30}];
          // for (const key in ws) {
          //   console.log(key);
          //   if (key.replace(/[^0-9]/gi, '') === '1') {

          //     ws[key].s = {
          //       alignment: {horizontal: 'center', vertical: 'center'},
          //     };
          //   }
          // }
          // ws['A1'].s = {alignment: {horizontal: 'center', vertical: 'center'}};
          // xlsx.utils.book_append_sheet(wb, ws, wsname); //将数据添加到工作薄
          // xlsx.writeFile(wb, filename); //导出Excel
        }
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      console.log(e);
      this.$message.error('网络异常，请稍后重试');
    } finally {
      this.loading = false;
    }
  }
  //导出明细
  private async exportReport() {
    this.loading = true;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const reportList: any = [];
    try {
      //店铺搜索
      let searchShop: Array<ObjectId> = [];
      if (this.searchData.label.length > 0) {
        const shop = await shops.find(stage =>
          stage.$match(match => {
            match(
              f => f('spec')('applicationId'),
              e =>
                e.$eq(
                  ObjectId.createFromHexString(
                    this.$route.params.applicationId,
                  ),
                ),
            );
            if (this.searchData.shopId) {
              match(
                f => f('_id'),
                e =>
                  e.$eq(ObjectId.createFromHexString(this.searchData.shopId)),
              );
            } else {
              if (this.role === '店铺' || this.role === '财务') {
                match(
                  f => f('_id'),
                  e => e.$in(this.shopAuthority),
                );
              }
            }

            if (this.searchData.label.length > 0) {
              match(
                f => f('spec')('labels'),
                e => e.$in(this.searchData.label),
              );
            }
            return match;
          }),
        );
        searchShop = shop.map(v => v._id);
      } else {
        if (this.searchData.shopId) {
          searchShop = [ObjectId.createFromHexString(this.searchData.shopId)];
        } else {
          searchShop = this.shopAuthority;
        }
      }
      if (this.searchData.label.length > 0 && searchShop.length === 0) {
        this.$message.warning('暂无数据');
      } else {
        const payTransactionCount = await payTransactions.find(stage =>
          stage
            .$match(match =>
              match.$and(and => {
                and(query => {
                  if (searchShop.length > 0) {
                    query(
                      f => f('spec')('shopId'),
                      e => e.$in(searchShop),
                    );
                  }
                  query(
                    f => f('spec')('applicationId'),
                    e =>
                      e.$eq(
                        ObjectId.createFromHexString(
                          this.$route.params.applicationId,
                        ),
                      ),
                  )(
                    f => f('spec')('orderType'),
                    e => e.$eq('商城'),
                  );
                  if (this.searchData.payMerchantId) {
                    query(
                      f => f('spec')('payMerchantId'),
                      e =>
                        e.$eq(
                          ObjectId.createFromHexString(
                            this.searchData.payMerchantId,
                          ),
                        ),
                    );
                  }
                  if (this.searchData.payMerchantType) {
                    query(
                      f => f('spec')('payMerchantType'),
                      e => e.$eq(this.searchData.payMerchantType as '微信'),
                    );
                  }
                  if (this.searchData.id) {
                    query(
                      f => f('_id'),
                      e =>
                        e.$eq(ObjectId.createFromHexString(this.searchData.id)),
                    );
                  }
                  if (this.searchData.orderId) {
                    query(
                      f => f('spec')('orderId'),
                      e =>
                        e.$eq(
                          ObjectId.createFromHexString(this.searchData.orderId),
                        ),
                    );
                  }
                  return query;
                });
                if (this.searchData.time && this.searchData.time.length > 0) {
                  and(query =>
                    query(
                      f => f('metadata')('updationTimestamp'),
                      e => e.$gte(moment(this.searchData.time[0]).toDate()),
                    ),
                  );
                  and(query =>
                    query(
                      f => f('metadata')('updationTimestamp'),
                      e =>
                        e.$lte(
                          moment(this.searchData.time[1])
                            .add({day: 1})
                            .subtract({millisecond: 1})
                            .toDate(),
                        ),
                    ),
                  );
                }
                return and;
              }),
            )
            .$facet(facet =>
              facet('count', countStage => countStage.$count('count')),
            ),
        );
        const count = payTransactionCount[0].count[0]
          ? payTransactionCount[0].count[0].count.valueOf()
          : 0;
        if (count === 0) {
          this.$message.warning('暂无数据');
        } else {
          if (this.role === '店铺' || this.role === '财务') {
            reportList.push([
              '记账时间',
              '流水单号',
              '关联订单号',
              '支付方式',
              '店铺名称',
              '收支(￥)',
              '商品金额(￥)',
              '商品成本价(￥)',
              '包装费(￥)',
              '配送费(￥)',
              '服务费(￥)',
              '店铺满减(￥)',
              '平台满减(￥)',
              '店铺优惠(￥)',
              '平台优惠(￥)',
            ]);
          } else {
            reportList.push([
              '记账时间',
              '流水单号',
              '关联订单号',
              '支付商户',
              '支付方式',
              '店铺名称',
              '收支(￥)',
              '商品金额(￥)',
              '商品成本价(￥)',
              '包装费(￥)',
              '配送费(￥)',
              '服务费(￥)',
              '店铺满减(￥)',
              '平台满减(￥)',
              '店铺优惠(￥)',
              '平台优惠(￥)',
            ]);
          }

          let i = 0;
          while (i < Math.ceil(count / 100)) {
            i++;
            const list = await payTransactions.find(stage =>
              stage
                .$match(match =>
                  match.$and(and => {
                    and(query => {
                      query(
                        f => f('spec')('applicationId'),
                        e =>
                          e.$eq(
                            ObjectId.createFromHexString(
                              this.$route.params.applicationId,
                            ),
                          ),
                      )(
                        f => f('spec')('orderType'),
                        e => e.$eq('商城'),
                      );
                      if (searchShop.length > 0) {
                        query(
                          f => f('spec')('shopId'),
                          e => e.$in(searchShop),
                        );
                      }
                      if (this.searchData.payMerchantId) {
                        query(
                          f => f('spec')('payMerchantId'),
                          e =>
                            e.$eq(
                              ObjectId.createFromHexString(
                                this.searchData.payMerchantId,
                              ),
                            ),
                        );
                      }
                      if (this.searchData.payMerchantType) {
                        query(
                          f => f('spec')('payMerchantType'),
                          e => e.$eq(this.searchData.payMerchantType as '微信'),
                        );
                      }
                      if (this.searchData.id) {
                        query(
                          f => f('_id'),
                          e =>
                            e.$eq(
                              ObjectId.createFromHexString(this.searchData.id),
                            ),
                        );
                      }
                      if (this.searchData.orderId) {
                        query(
                          f => f('spec')('orderId'),
                          e =>
                            e.$eq(
                              ObjectId.createFromHexString(
                                this.searchData.orderId,
                              ),
                            ),
                        );
                      }
                      return query;
                    });
                    if (
                      this.searchData.time &&
                      this.searchData.time.length > 0
                    ) {
                      and(query =>
                        query(
                          f => f('metadata')('updationTimestamp'),
                          e => e.$gte(moment(this.searchData.time[0]).toDate()),
                        ),
                      );
                      and(query =>
                        query(
                          f => f('metadata')('updationTimestamp'),
                          e =>
                            e.$lte(
                              moment(this.searchData.time[1])
                                .add({day: 1})
                                .subtract({millisecond: 1})
                                .toDate(),
                            ),
                        ),
                      );
                    }
                    return and;
                  }),
                )
                .$facet(facet =>
                  facet('table', tableStage =>
                    tableStage
                      .$sort(sort =>
                        sort(f => f('metadata')('updationTimestamp'), '降序')(
                          f => f('_id'),
                          '降序',
                        ),
                      )
                      .$skip((i - 1) * 100)
                      .$limit(100),
                  ),
                ),
            );
            let shopIdData: Array<ObjectId> = [];
            list[0].table.forEach(item => {
              if (item.spec.orderType === '商城' && item.spec.shopId) {
                shopIdData.push(item.spec.shopId);
              }
            });
            shopIdData = Array.from(new Set(shopIdData));
            const shopData = await shops.find(stage =>
              stage.$match(match =>
                match(
                  f => f('_id'),
                  e => e.$in(shopIdData),
                )(
                  f => f('spec')('applicationId'),
                  e =>
                    e.$eq(
                      ObjectId.createFromHexString(
                        this.$route.params.applicationId,
                      ),
                    ),
                ),
              ),
            );
            const shopInfoData = lodash.zipObject(
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              shopData.map(v => v._id.toHexString()) as any,
              shopData,
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
            ) as any;
            list[0].table.forEach(item => {
              if (item.spec.orderType === '商城') {
                if (this.role === '店铺' || this.role === '财务') {
                  reportList.push([
                    this.getTime(item.metadata.updationTimestamp ?? ''),
                    item._id.toHexString(),
                    item.spec.orderId.toHexString(),
                    item.spec.payMerchantType,
                    shopInfoData[item.spec.shopId.toHexString()]
                      ? shopInfoData[item.spec.shopId.toHexString()].spec.name
                      : '',
                    Number((item.spec.amount.user / 100).toFixed(2)),
                    Number((item.spec.amount.product / 100).toFixed(2)),
                    item.spec.amount.productCost
                      ? Number((item.spec.amount.productCost / 100).toFixed(2))
                      : 0,
                    Number((item.spec.amount.packing / 100).toFixed(2)),
                    Number((item.spec.amount.delivery / 100).toFixed(2)),
                    item.spec.amount.service
                      ? Number((item.spec.amount.service / 100).toFixed(2))
                      : 0,
                    item.spec.amount.fullReduction
                      ? Number(
                          (item.spec.amount.fullReduction.shop / 100).toFixed(
                            2,
                          ),
                        )
                      : 0,
                    item.spec.amount.fullReduction
                      ? Number(
                          (
                            item.spec.amount.fullReduction.application / 100
                          ).toFixed(2),
                        )
                      : 0,
                    item.spec.amount.coupon
                      ? Number((item.spec.amount.coupon.shop / 100).toFixed(2))
                      : 0,
                    item.spec.amount.coupon
                      ? Number(
                          (item.spec.amount.coupon.application / 100).toFixed(
                            2,
                          ),
                        )
                      : 0,
                  ]);
                } else {
                  reportList.push([
                    this.getTime(item.metadata.updationTimestamp ?? ''),
                    item._id.toHexString(),
                    item.spec.orderId.toHexString(),
                    this.getPayMerchantName(item.spec.payMerchantId),
                    item.spec.payMerchantType,
                    shopInfoData[item.spec.shopId.toHexString()]
                      ? shopInfoData[item.spec.shopId.toHexString()].spec.name
                      : '',
                    Number((item.spec.amount.user / 100).toFixed(2)),
                    Number((item.spec.amount.product / 100).toFixed(2)),
                    item.spec.amount.productCost
                      ? Number((item.spec.amount.productCost / 100).toFixed(2))
                      : 0,
                    Number((item.spec.amount.packing / 100).toFixed(2)),
                    Number((item.spec.amount.delivery / 100).toFixed(2)),
                    item.spec.amount.service
                      ? Number((item.spec.amount.service / 100).toFixed(2))
                      : 0,
                    item.spec.amount.fullReduction
                      ? Number(
                          (item.spec.amount.fullReduction.shop / 100).toFixed(
                            2,
                          ),
                        )
                      : 0,
                    item.spec.amount.fullReduction
                      ? Number(
                          (
                            item.spec.amount.fullReduction.application / 100
                          ).toFixed(2),
                        )
                      : 0,
                    item.spec.amount.coupon
                      ? Number((item.spec.amount.coupon.shop / 100).toFixed(2))
                      : 0,
                    item.spec.amount.coupon
                      ? Number(
                          (item.spec.amount.coupon.application / 100).toFixed(
                            2,
                          ),
                        )
                      : 0,
                  ]);
                }
              }
            });
          }
          const filename = moment().format('YYYY-MM-DD') + '商城账单明细.xlsx'; //文件名称
          const wsname = 'Sheet1'; //Excel第一个sheet的名称
          const wb = xlsx.utils.book_new(),
            ws = xlsx.utils.aoa_to_sheet(reportList);
          xlsx.utils.book_append_sheet(wb, ws, wsname); //将数据添加到工作薄
          xlsx.writeFile(wb, filename); //导出Excel
        }
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      console.log(e);
      this.$message.error('网络异常，请稍后重试');
    } finally {
      this.loading = false;
    }
  }
}
