
import {Component, Vue} from 'vue-property-decorator';
import Pagination from '@/components/Pagination/index.vue';
import {orders, shops} from '../../../../../resources';
import moment from 'moment';
import {ObjectId} from 'bson';
import xlsx from 'xlsx';
import {Shop} from '@/externals/MaxCI-Shop-v1';
import {EJSON} from 'bson';

interface StockList {
  _id: {
    productId: ObjectId;
    sku: string;
  };
  name: string;
  count: number;
  index?: number;
}
@Component({
  name: 'stockingList',
  components: {
    Pagination,
  },
})
export default class extends Vue {
  private loading = false;
  private searchData = {name: '', time: []};
  private dialogDel = false;
  private operateId = '';
  private total = 0;
  private list: Array<StockList> = [];
  private listLoading = true;
  private listParams = {
    page: 1,
    limit: 10,
  };
  private shopData: Array<Shop> = [];
  private applicationId = ObjectId.createFromHexString(
    this.$route.params.applicationId,
  );
  private role = localStorage.getItem('role');
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private shopAuthority: any = localStorage.getItem('shopAuthority')
    ? EJSON.parse(localStorage.getItem('shopAuthority') ?? '')
    : '';

  async created() {
    this.shopData = (
      await shops.find(stage =>
        stage
          .$match(match => {
            if (this.role === '店铺') {
              match(
                f => f('_id'),
                e => e.$in(this.shopAuthority),
              );
            }
            if (this.searchData.name) {
              match(
                f => f('spec')('name'),
                e => e.$regex(new RegExp(this.searchData.name)),
              );
            }
            match(
              f => f('spec')('applicationId'),
              e => e.$eq(this.applicationId),
            );
            return match;
          })
          .$facet(facet =>
            facet('table', tableStage =>
              tableStage.$sort(sort =>
                sort(f => f('metadata')('creationTimestamp'), '降序'),
              ),
            ),
          ),
      )
    )[0].table;
    if (this.shopData.length > 0) {
      this.searchData.name = this.shopData[0]._id.toHexString();
    }
    this.updateList();
  }
  private getAttr(attr: Array<string>) {
    return attr.length === 0 ? '' : `/${attr.join('/')}`;
  }
  //转换时间
  getTime(time: string) {
    return moment(time).format('YYYY-MM-DD HH:mm:ss');
  }
  //选择自定义日期
  private changeTimeFrame() {
    this.checkList();
  }
  //导出
  private async exportReport() {
    this.loading = true;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const reportList: any = [];
    try {
      reportList.push(['商品名称', '规格/属性', '数量']);
      const list = await orders.find(stage =>
        stage
          .$match(match => {
            match(
              f => f('spec')('applicationId'),
              e => e.$eq(this.applicationId),
            )(
              f => f('spec')('type'),
              e => e.$eq('商城'),
            )(
              f => f('status')('phase'),
              e => e.$in(['支付', '接单', '打包']),
            )(
              f => f('spec')('shopId'),
              e => e.$eq(ObjectId.createFromHexString(this.searchData.name)),
            );
            return match;
          })
          .$replaceWith(replaceWith =>
            replaceWith.$object(object =>
              object('items', e => e.$fieldPath(f => f('spec')('items'))),
            ),
          )
          .$unwind(f => f('items'), undefined, false)
          .$group(
            e =>
              e.$object(object =>
                object('productId', e =>
                  e.$fieldPath(f => f('items')('productId')),
                )('sku', e => e.$fieldPath(f => f('items')('sku')('name'))),
              ),
            group =>
              group('count', e =>
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                e.$sum(sum => sum.$fieldPath(f => f('items')('count'))),
              )('name', e =>
                e.$first(first =>
                  first.$fieldPath(f => f('items')('snapshot')('name')),
                ),
              ),
          ),
      );
      list.forEach(item => {
        reportList.push([item.name, item._id.sku, item.count]);
      });
      const filename = moment().format('YYYY-MM-DD') + '备货单.xlsx'; //文件名称
      const wsname = 'Sheet1'; //Excel第一个sheet的名称
      const wb = xlsx.utils.book_new(),
        ws = xlsx.utils.aoa_to_sheet(reportList);
      xlsx.utils.book_append_sheet(wb, ws, wsname); //将数据添加到工作薄
      xlsx.writeFile(wb, filename); //导出Excel
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      this.$message.error('网络异常，请稍后重试');
    } finally {
      this.loading = false;
    }
  }
  //搜索数据
  private checkList() {
    this.listParams.page = 1;
    this.updateList();
  }
  private async updateList() {
    this.listLoading = true;
    try {
      const list = await orders.find(stage =>
        stage
          .$match(match =>
            match.$and(and => {
              and(query => {
                query(
                  f => f('spec')('applicationId'),
                  e => e.$eq(this.applicationId),
                )(
                  f => f('spec')('type'),
                  e => e.$eq('商城'),
                )(
                  f => f('status')('phase'),
                  e => e.$in(['支付', '接单', '打包']),
                )(
                  f => f('spec')('shopId'),
                  e =>
                    e.$eq(ObjectId.createFromHexString(this.searchData.name)),
                );
                return query;
              });
              if (this.searchData.time && this.searchData.time.length > 0) {
                and(query =>
                  query(
                    f => f('metadata')('updationTimestamp'),
                    e => e.$gte(moment(this.searchData.time[0]).toDate()),
                  ),
                );
                and(query =>
                  query(
                    f => f('metadata')('updationTimestamp'),
                    e =>
                      e.$lte(
                        moment(this.searchData.time[1])
                          .add({day: 1})
                          .subtract({millisecond: 1})
                          .toDate(),
                      ),
                  ),
                );
              }
              return and;
            }),
          )
          .$replaceWith(replaceWith =>
            replaceWith.$object(object =>
              object('items', e => e.$fieldPath(f => f('spec')('items'))),
            ),
          )
          .$unwind(f => f('items'), undefined, false)
          .$group(
            e =>
              e.$object(object =>
                object('productId', e =>
                  e.$fieldPath(f => f('items')('productId')),
                )('sku', e => e.$fieldPath(f => f('items')('sku')('name')))(
                  'attributes',
                  e => e.$fieldPath(f => f('items')('attributes')),
                ),
              ),
            group =>
              group('count', e =>
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                e.$sum(sum => sum.$fieldPath(f => f('items')('count'))),
              )('name', e =>
                e.$first(first =>
                  first.$fieldPath(f => f('items')('snapshot')('name')),
                ),
              ),
          )
          .$facet(facet =>
            facet('table', tableStage =>
              tableStage
                .$skip((this.listParams.page - 1) * this.listParams.limit)
                .$limit(this.listParams.limit),
            )('count', countStage => countStage.$count('count')),
          ),
      );
      this.total = list[0].count[0] ? list[0].count[0].count.valueOf() : 0;
      this.list = list[0].table;
      this.list.forEach((e, i) => {
        e.index = (this.listParams.page - 1) * this.listParams.limit + i + 1;
      });
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      this.$message.error('网络异常，请稍后重试');
    } finally {
      this.listLoading = false;
    }
  }
}
