
import {Component, Vue} from 'vue-property-decorator';
import Pagination from '@/components/Pagination/index.vue';
import {productLabels, products} from '../../../../../resources';
import moment from 'moment';
import {ObjectId} from 'bson';
import draggable from 'vuedraggable';
import inputClose from '@/components/inputClose/index.vue';
import {AxiosError} from 'axios';
import $ from 'jquery';

interface List {
  _id: ObjectId | null;
  name: string;
  values: Array<string>;
  multiSelect: boolean;
  value: string;
  verification: boolean;
  warnPrompt: boolean;
}
@Component({
  name: 'shopLabelList',
  components: {
    Pagination,
    draggable,
    inputClose,
  },
})
export default class extends Vue {
  private labelPrefix = '商品标签:'; //商品标签的前缀
  private operateId: ObjectId | null = null;
  private operateName = '';
  private dialogDel = false; //删除弹窗
  private searchData = {name: ''};
  private total = 0;
  private list: Array<List> = [];
  private oldList: Array<List> = [];
  private listLoading = true;
  private listParams = {
    page: 1,
    limit: 10,
  };
  private applicationId = ObjectId.createFromHexString(
    this.$route.params.applicationId,
  );

  async created() {
    this.updateList();
  }
  //新增
  private async addBtn() {
    // await this.checkList();
    this.list.unshift({
      _id: null,
      name: '',
      values: [],
      multiSelect: false,
      value: '',
      verification: false,
      warnPrompt: false,
    });
    $('#name_text0').focus();
  }
  private idToString(id: ObjectId | null) {
    if (id) {
      return id.toHexString();
    } else {
      return '';
    }
  }
  //转换时间
  getTime(time: string) {
    return moment(time).format('YYYY-MM-DD HH:mm:ss');
  }
  //搜索数据
  private checkList() {
    this.listParams.page = 1;
    this.updateList();
  }
  private async updateList() {
    this.listLoading = true;
    try {
      const list = await productLabels.find(stage =>
        stage
          .$match(match =>
            match.$and(and => {
              and(query =>
                query(
                  f => f('spec')('applicationId'),
                  e => e.$eq(this.applicationId),
                ),
              );
              and(query =>
                query(
                  f => f('spec')('name'),
                  e => e.$regex(new RegExp('商品标签:')),
                ),
              );
              if (this.searchData.name) {
                and(query =>
                  query(
                    f => f('spec')('name'),
                    e => e.$regex(new RegExp(this.searchData.name)),
                  ),
                );
              }
              return and;
            }),
          )
          .$facet(facet =>
            facet('table', tableStage =>
              tableStage
                .$sort(sort =>
                  sort(f => f('metadata')('creationTimestamp'), '降序'),
                )
                .$skip((this.listParams.page - 1) * this.listParams.limit)
                .$limit(this.listParams.limit),
            )('count', countStage => countStage.$count('count')),
          ),
      );
      this.total = list[0].count[0] ? list[0].count[0].count.valueOf() : 0;
      this.list = list[0].table.map(v => {
        return {
          _id: v._id,
          name: v.spec.name.slice(5, v.spec.name.length),
          values: v.spec.values.map(v => v),
          multiSelect: v.spec.multiSelect,
          value: '',
          verification: false,
          warnPrompt: false,
        };
      });
      this.oldList = list[0].table.map(v => {
        return {
          _id: v._id,
          name: v.spec.name.slice(5, v.spec.name.length),
          values: v.spec.values.map(v => v),
          multiSelect: v.spec.multiSelect,
          value: '',
          verification: false,
          warnPrompt: false,
        };
      });
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      this.$message.error('网络异常，请稍后重试');
    } finally {
      this.listLoading = false;
    }
  }
  //添加标签的值
  private async addValue(index: number) {
    let num = 0;
    this.list[index].values.forEach(item => {
      if (item === this.list[index].value) {
        num++;
      }
    });
    this.list[index].verification = false;
    this.list[index].warnPrompt = false;
    if (this.list[index].value) {
      if (num === 0) {
        if (this.list[index].value.length > 15) {
          this.list[index].warnPrompt = true;
        } else {
          const productLabel = await productLabels.update(
            filter =>
              filter(
                f => f('_id'),
                e => e.$eq(this.list[index]._id as ObjectId),
              )(
                f => f('spec')('applicationId'),
                e => e.$eq(this.applicationId),
              ),
            update =>
              update.$push(p =>
                p(f => f('spec')('values'), this.list[index].value),
              ),
          );
          if (productLabel.length > 0) {
            this.updateList();
          }
        }
      } else {
        this.list[index].verification = true;
      }
    }
  }
  //修改名称
  private async changeName(index: number) {
    if (this.list[index]._id) {
      //修改
      if (this.list[index].name) {
        if (this.list[index].name !== this.oldList[index].name) {
          const productLabel = await productLabels.update(
            filter =>
              filter(
                f => f('_id'),
                e => e.$eq(this.list[index]._id as ObjectId),
              )(
                f => f('spec')('applicationId'),
                e => e.$eq(this.applicationId),
              ),
            update =>
              update.$set(s =>
                s(
                  f => f('spec')('name'),
                  this.labelPrefix + this.list[index].name,
                ),
              ),
          );
          const oldLabel = this.oldList[index].values.map(v => {
            return this.oldList[index].name + ':' + v;
          });
          const label = this.list[index].values.map(v => {
            return this.list[index].name + ':' + v;
          });
          if (productLabel.length > 0) {
            oldLabel.forEach((item, index) => {
              this.changeLabel(item, label[index]);
            });
          }
        }
      } else {
        this.list[index].name = this.oldList[index].name;
      }
    } else {
      //新增
      if (this.list[index].name) {
        try {
          const productLabel = await productLabels.create([
            {
              spec: {
                name: this.labelPrefix + this.list[index].name,
                values: [],
                multiSelect: false,
                applicationId: this.applicationId,
              },
            },
          ]);
          if (productLabel.length > 0) {
            productLabels.update(
              filter =>
                filter(
                  f => f('spec')('name'),
                  e => e.$eq('商品标签'),
                )(
                  f => f('spec')('applicationId'),
                  e => e.$eq(this.applicationId),
                ),
              update =>
                update
                  .$push(p =>
                    p(f => f('spec')('values'), {
                      // eslint-disable-next-line @typescript-eslint/no-explicit-any
                      $each: [productLabel[0]._id.toHexString()] as any,
                      $position: 0,
                    }),
                  )
                  .$set(s => s(f => f('spec')('multiSelect'), false)),
              {
                upsert: true,
              },
            );
            this.updateList();
          }
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (e: any) {
          if (e.isAxiosError) {
            const axiosError = e as AxiosError;
            if (
              axiosError.response?.status === 500 &&
              (axiosError.response.data.name === 'MongoError' ||
                axiosError.response.data.name === 'Error') &&
              axiosError.response.data.message.indexOf('E11000') > -1
            ) {
              this.$message.error('标签名称不能重复');
            }
          }
        }
      } else {
        this.list.shift();
      }
    }
  }
  //修改标签
  private async changeLabel(oldName: string, name: string) {
    await products.update(
      filter =>
        filter(
          f => f('spec')('labels'),
          e => e.$in([this.labelPrefix + oldName]),
        )(
          f => f('spec')('applicationId'),
          e => e.$eq(this.applicationId),
        ),
      update =>
        update.$set(s =>
          s(f => f('spec')('labels')('$'), this.labelPrefix + name),
        ),
    );
  }
  //修改是否多选
  private changeMultiSelect(id: ObjectId, e: boolean) {
    productLabels.update(
      filter =>
        filter(
          f => f('_id'),
          e => e.$eq(id),
        )(
          f => f('spec')('applicationId'),
          e => e.$eq(this.applicationId),
        ),
      update => update.$set(s => s(f => f('spec')('multiSelect'), e)),
    );
  }
  //删除提示
  private delPopUps(id: ObjectId, name: string) {
    this.operateId = id;
    this.operateName = name;
    this.dialogDel = true;
  }
  //删除保存
  private async delSumbit() {
    try {
      if (this.operateId) {
        const productLabel = await productLabels.delete(filter =>
          filter(
            f => f('_id'),
            e => e.$eq(this.operateId as ObjectId),
          )(
            f => f('spec')('applicationId'),
            e => e.$eq(this.applicationId),
          ),
        );
        if (productLabel) {
          productLabels.update(
            filter =>
              filter(
                f => f('spec')('name'),
                e => e.$eq('商品标签'),
              )(
                f => f('spec')('applicationId'),
                e => e.$eq(this.applicationId),
              ),
            update =>
              update.$pull(s =>
                s(
                  f => f('spec')('values'),
                  'value',
                  (this.operateId as ObjectId).toHexString(),
                ),
              ),
          );
          products.update(
            filter =>
              filter(
                f => f('spec')('applicationId'),
                e => e.$eq(this.applicationId),
              ),
            update =>
              update.$pull(s =>
                s(
                  f => f('spec')('labels'),
                  'expression',
                  e =>
                    e.$regex(
                      new RegExp(`^${this.labelPrefix}${this.operateName}:.+$`),
                    ),
                ),
              ),
          );
          this.dialogDel = false;
          this.operateId = null;
          this.$message.success('已删除');
          this.updateList();
        }
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      this.$message.error('网络异常，请稍后重试');
    }
  }
  //排序
  private changePosition(index: number) {
    productLabels.update(
      filter =>
        filter(
          f => f('_id'),
          e => e.$eq(this.list[index]._id as ObjectId),
        )(
          f => f('spec')('applicationId'),
          e => e.$eq(this.applicationId),
        ),
      update =>
        update.$set(s => s(f => f('spec')('values'), this.list[index].values)),
    );
  }
}
