var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.saveLoading),expression:"saveLoading"}],staticClass:"rigth_menu",attrs:{"element-loading-text":"保存中..."}},[_c('div',{staticClass:"rm_top"},[_vm._v("通用配置")]),_c('div',{staticClass:"rm_con"},[_c('div',{staticClass:"features_box"},[_c('div',{staticClass:"features_group"},[_c('el-form',{ref:"formData",attrs:{"label-width":"120px","model":_vm.formData,"rules":_vm.rules}},[_c('el-form-item',{attrs:{"label":"打印取消订单：","prop":"printCancel"}},[_c('el-switch',{model:{value:(_vm.formData.printCancel),callback:function ($$v) {_vm.$set(_vm.formData, "printCancel", $$v)},expression:"formData.printCancel"}})],1),_c('el-form-item',{attrs:{"label":"打印图标","prop":"logo"}},[_c('div',{staticClass:"uploader_box"},[_c('upload-image',{attrs:{"src":_vm.formData.printLogo,"width":"100px","height":"100px","process":[
                  {
                    type: '缩放',
                    mode: 'fill',
                    width: 350,
                    height: 350,
                  },
                ]},on:{"changeImage":_vm.changeUploadImage}})],1),_c('div',{staticStyle:{"font-size":"12px","color":"#999"}},[_vm._v(" 建议上传350*350大小的图片 ")])]),_c('el-form-item',{attrs:{"label":"","prop":""}},[_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.submitSave}},[_vm._v("保存")])],1)],1)],1)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }