
import {Component, Vue} from 'vue-property-decorator';
import operate from './operate.vue';
import {ObjectId} from 'bson';

@Component({
  name: 'deliveryMerchantsEdit',
  components: {
    operate,
  },
})
export default class extends Vue {
  private detailId = ObjectId.createFromHexString(
    this.$route.query.id as string,
  );
}
