
import {Component, Vue} from 'vue-property-decorator';
import operate from './operate.vue';

@Component({
  name: 'deliveryMerchantsAdd',
  components: {
    operate,
  },
})
export default class extends Vue {}
