
import {Component, Vue} from 'vue-property-decorator';
import Submenu from '@/components/Submenu/index.vue';
import mallBillingDetails from './components/statistics/billingDetails/index.vue';
import statisticsTemplate from './components/statistics/statisticsTemplate/index.vue';
import ShopLabel from './components/shop/shopLabel/index.vue';
import ShopList from './components/shop/shopList/index.vue';
import goodsLabel from './components/shop/goodsLabel/index.vue';
import deliveryMerchants from './components/deliveryMerchants/index.vue';
import stockingList from './components/shop/stockingList/index.vue';
import mallSetting from './components/mallSetting/index.vue';
import printSetting from './components/printSetting/index.vue';
import customerService from './components/customer-service/index.vue';
@Component({
  name: 'mall',
  components: {
    Submenu,
    mallBillingDetails,
    statisticsTemplate,
    ShopLabel,
    ShopList,
    goodsLabel,
    deliveryMerchants,
    stockingList,
    mallSetting,
    printSetting,
    customerService,
  },
})
export default class extends Vue {
  private menu = '';
  private submenu = [
    {
      top: '店铺管理',
      list: [
        {name: '店铺列表', active: true},
        {name: '店铺标签', active: false},
        {name: '商品标签', active: false},
        {name: '备货单', active: false},
      ],
    },
    {
      top: '配置',
      list: [
        {name: '配送服务商', active: false},
        {name: '通用配置', active: false},
        {name: '打印配置', active: false},
      ],
    },
  ];
  created() {
    this.$store.state.applicationId = this.$route.params.applicationId;
    const role = localStorage.getItem('role');
    if (role === '店铺') {
      this.submenu = [
        {
          top: '店铺管理',
          list: [
            {name: '店铺列表', active: true},
            {name: '备货单', active: false},
          ],
        },
      ];
      this.menu = this.$route.query.menu
        ? (this.$route.query.menu as string)
        : '店铺列表';
    } else if (role === '财务') {
      // this.submenu = [
      //   {
      //     top: '账单统计',
      //     list: [{name: '账单明细', active: false}],
      //   },
      // ];
      // this.menu = this.$route.query.menu
      //   ? (this.$route.query.menu as string)
      //   : '账单明细';
    } else {
      this.submenu = [
        {
          top: '店铺管理',
          list: [
            {name: '店铺列表', active: true},
            {name: '店铺标签', active: false},
            {name: '商品标签', active: false},
            {name: '备货单', active: false},
          ],
        },
        {
          top: '配置',
          list: [
            {name: '配送服务商', active: false},
            {name: '通用配置', active: false},
            {name: '打印配置', active: false},
            {name: '客服配置', active: false},
          ],
        },
      ];
      this.menu = this.$route.query.menu
        ? (this.$route.query.menu as string)
        : '店铺列表';
    }
  }
}
