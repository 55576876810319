
import {Component, Vue, Prop, Watch} from 'vue-property-decorator';
import {ObjectId} from 'bson';
import {
  shopLabels,
  shops,
  productLabels,
  products,
  deliveryShopLabels,
  deliveryShops,
  usedProductLabels,
  groupDeliveryPointLabels,
  groupDeliveryPoints,
  questionLabels,
  questions,
} from '../../resources';

interface List {
  _id: ObjectId | null;
  name: string;
  values: Array<string>;
  multiSelect: boolean;
  value: string;
  verification: boolean;
}
@Component({
  name: 'inputClose',
})
export default class extends Vue {
  @Prop()
  private type: string;
  @Prop()
  private value: string;
  @Prop()
  private index: number;
  @Prop()
  private valueIndex: number;
  @Prop()
  private list: Array<List>;
  @Prop()
  private oldList: Array<List>;
  @Prop()
  private prefix: string;
  private id = '';

  private currentValue = '';
  private focusFlag = false;
  private warnPrompt = false;
  async created() {
    this.id =
      (this.list[this.index]._id?.toHexString() ?? '') + this.valueIndex;
    this.currentValue = this.value;
  }
  @Watch('value')
  private modifyValue(val: string) {
    this.currentValue = val;
  }
  private upValue() {
    this.focusFlag = true;
    document.getElementById(this.id)?.focus();
  }
  //修改标签的值
  private async changeVal() {
    this.focusFlag = false;
    this.warnPrompt = false;
    //是否为空
    if (this.currentValue) {
      let num = 0;
      this.oldList[this.index].values.forEach(item => {
        if (item === this.currentValue) {
          num++;
        }
      });
      if (num === 0) {
        if (this.type === 'product') {
          if (this.currentValue.length > 15) {
            this.warnPrompt = true;
          } else {
            this.warnPrompt = false;
            const productLabel = await productLabels.update(
              filter =>
                filter(
                  f => f('_id'),
                  e => e.$eq(this.list[this.index]._id as ObjectId),
                )(
                  f => f('spec')('applicationId'),
                  e =>
                    e.$eq(
                      ObjectId.createFromHexString(
                        this.$route.params.applicationId,
                      ),
                    ),
                ),
              update =>
                update.$set(s =>
                  s(
                    f => f('spec')('values')(this.valueIndex),
                    this.currentValue,
                  ),
                ),
            );
            if (productLabel.length > 0 && this.prefix === '商品标签:') {
              this.$emit(
                'changeLabel',
                this.list[this.index].name +
                  ':' +
                  this.oldList[this.index].values[this.valueIndex],
                this.list[this.index].name + ':' + this.currentValue,
              );
            }
          }
        } else if (this.type === 'shop') {
          if (this.currentValue.length > 15) {
            this.warnPrompt = true;
          } else {
            this.warnPrompt = false;
            const shopLabel = await shopLabels.update(
              filter =>
                filter(
                  f => f('_id'),
                  e => e.$eq(this.list[this.index]._id as ObjectId),
                )(
                  f => f('spec')('applicationId'),
                  e =>
                    e.$eq(
                      ObjectId.createFromHexString(
                        this.$route.params.applicationId,
                      ),
                    ),
                ),
              update =>
                update.$set(s =>
                  s(
                    f => f('spec')('values')(this.valueIndex),
                    this.currentValue,
                  ),
                ),
            );
            if (shopLabel.length > 0) {
              this.$emit(
                'changeLabel',
                this.list[this.index].name +
                  ':' +
                  this.oldList[this.index].values[this.valueIndex],
                this.list[this.index].name + ':' + this.currentValue,
              );
            }
          }
        } else if (this.type === 'spec') {
          this.$emit(
            'changeValue',
            this.index,
            this.valueIndex,
            this.currentValue,
          );
          // if (getStrLength(this.currentValue) > 10) {
          //   this.warnPrompt = true;
          // } else {
          //   this.warnPrompt = false;
          //   this.$emit(
          //     'changeValue',
          //     this.index,
          //     this.valueIndex,
          //     this.currentValue,
          //   );
          // }
        } else if (this.type === 'store') {
          if (this.currentValue.length > 15) {
            this.warnPrompt = true;
          } else {
            this.warnPrompt = false;
            const deliveryShopLabel = await deliveryShopLabels.update(
              filter =>
                filter(
                  f => f('_id'),
                  e => e.$eq(this.list[this.index]._id as ObjectId),
                )(
                  f => f('spec')('applicationId'),
                  e =>
                    e.$eq(
                      ObjectId.createFromHexString(
                        this.$route.params.applicationId,
                      ),
                    ),
                ),
              update =>
                update.$set(s =>
                  s(
                    f => f('spec')('values')(this.valueIndex),
                    this.currentValue,
                  ),
                ),
            );
            if (deliveryShopLabel.length > 0) {
              this.$emit(
                'changeLabel',
                this.list[this.index].name +
                  ':' +
                  this.oldList[this.index].values[this.valueIndex],
                this.list[this.index].name + ':' + this.currentValue,
              );
            }
          }
        } else if (this.type === 'used') {
          if (this.currentValue.length > 15) {
            this.warnPrompt = true;
          } else {
            this.warnPrompt = false;
            await usedProductLabels.update(
              filter =>
                filter(
                  f => f('_id'),
                  e => e.$eq(this.list[this.index]._id as ObjectId),
                )(
                  f => f('spec')('applicationId'),
                  e =>
                    e.$eq(
                      ObjectId.createFromHexString(
                        this.$route.params.applicationId,
                      ),
                    ),
                ),
              update =>
                update.$set(s =>
                  s(
                    f => f('spec')('values')(this.valueIndex),
                    this.currentValue,
                  ),
                ),
            );
          }
        } else if (this.type === 'point') {
          if (this.currentValue.length > 15) {
            this.warnPrompt = true;
          } else {
            this.warnPrompt = false;
            const groupDeliveryPointLabel =
              await groupDeliveryPointLabels.update(
                filter =>
                  filter(
                    f => f('_id'),
                    e => e.$eq(this.list[this.index]._id as ObjectId),
                  )(
                    f => f('spec')('applicationId'),
                    e =>
                      e.$eq(
                        ObjectId.createFromHexString(
                          this.$route.params.applicationId,
                        ),
                      ),
                  ),
                update =>
                  update.$set(s =>
                    s(
                      f => f('spec')('values')(this.valueIndex),
                      this.currentValue,
                    ),
                  ),
              );
            if (groupDeliveryPointLabel.length > 0) {
              this.$emit(
                'changeLabel',
                this.list[this.index].name +
                  ':' +
                  this.oldList[this.index].values[this.valueIndex],
                this.list[this.index].name + ':' + this.currentValue,
              );
            }
          }
        } else if (this.type === 'question') {
          if (this.currentValue.length > 15) {
            this.warnPrompt = true;
          } else {
            this.warnPrompt = false;
            const questionLabel = await questionLabels.update(
              filter =>
                filter(
                  f => f('_id'),
                  e => e.$eq(this.list[this.index]._id as ObjectId),
                )(
                  f => f('spec')('applicationId'),
                  e =>
                    e.$eq(
                      ObjectId.createFromHexString(
                        this.$route.params.applicationId,
                      ),
                    ),
                ),
              update =>
                update.$set(s =>
                  s(
                    f => f('spec')('values')(this.valueIndex),
                    this.currentValue,
                  ),
                ),
            );
            if (questionLabel.length > 0) {
              this.$emit(
                'changeLabel',
                this.list[this.index].name +
                  ':' +
                  this.oldList[this.index].values[this.valueIndex],
                this.list[this.index].name + ':' + this.currentValue,
              );
            }
          }
        }
      } else {
        this.list[this.index].values.splice(
          this.valueIndex,
          1,
          this.oldList[this.index].values[this.valueIndex],
        );
      }
    } else {
      this.currentValue = this.oldList[this.index].values[this.valueIndex];
    }
  }
  //删除标签的值
  private async closeValue() {
    if (this.type === 'product') {
      const productLabel = await productLabels.update(
        filter =>
          filter(
            f => f('_id'),
            e => e.$eq(this.list[this.index]._id as ObjectId),
          )(
            f => f('spec')('applicationId'),
            e =>
              e.$eq(
                ObjectId.createFromHexString(this.$route.params.applicationId),
              ),
          ),
        update =>
          update.$pull(p =>
            p(f => f('spec')('values'), 'value', this.currentValue),
          ),
      );
      if (productLabel.length > 0) {
        if (this.prefix === '商品标签:') {
          this.list[this.index].values.splice(this.valueIndex, 1);
          products.update(
            filter =>
              filter(
                f => f('spec')('labels'),
                e =>
                  e.$in([
                    this.prefix +
                      this.list[this.index].name +
                      ':' +
                      this.currentValue,
                  ]),
              )(
                f => f('spec')('applicationId'),
                e =>
                  e.$eq(
                    ObjectId.createFromHexString(
                      this.$route.params.applicationId,
                    ),
                  ),
              ),
            update =>
              update.$pull(p =>
                p(
                  f => f('spec')('labels'),
                  'value',
                  this.prefix +
                    this.list[this.index].name +
                    ':' +
                    this.currentValue,
                ),
              ),
          );
        }
        this.$emit('updateList');
      }
    } else if (this.type === 'shop') {
      const shopLabel = await shopLabels.update(
        filter =>
          filter(
            f => f('_id'),
            e => e.$eq(this.list[this.index]._id as ObjectId),
          )(
            f => f('spec')('applicationId'),
            e =>
              e.$eq(
                ObjectId.createFromHexString(this.$route.params.applicationId),
              ),
          ),
        update =>
          update.$pull(p =>
            p(f => f('spec')('values'), 'value', this.currentValue),
          ),
      );
      if (shopLabel.length > 0) {
        this.list[this.index].values.splice(this.valueIndex, 1);
        shops.update(
          filter =>
            filter(
              f => f('spec')('labels'),
              e =>
                e.$in([this.list[this.index].name + ':' + this.currentValue]),
            )(
              f => f('spec')('applicationId'),
              e =>
                e.$eq(
                  ObjectId.createFromHexString(
                    this.$route.params.applicationId,
                  ),
                ),
            ),
          update =>
            update.$pull(p =>
              p(
                f => f('spec')('labels'),
                'value',
                this.list[this.index].name + ':' + this.currentValue,
              ),
            ),
        );
        this.$emit('updateList');
      }
    } else if (this.type === 'spec') {
      this.$emit('delValue', this.index, this.valueIndex);
    } else if (this.type === 'store') {
      const deliveryShopLabel = await deliveryShopLabels.update(
        filter =>
          filter(
            f => f('_id'),
            e => e.$eq(this.list[this.index]._id as ObjectId),
          )(
            f => f('spec')('applicationId'),
            e =>
              e.$eq(
                ObjectId.createFromHexString(this.$route.params.applicationId),
              ),
          ),
        update =>
          update.$pull(p =>
            p(f => f('spec')('values'), 'value', this.currentValue),
          ),
      );
      if (deliveryShopLabel.length > 0) {
        this.list[this.index].values.splice(this.valueIndex, 1);
        deliveryShops.update(
          filter =>
            filter(
              f => f('spec')('labels'),
              e =>
                e.$in([this.list[this.index].name + ':' + this.currentValue]),
            )(
              f => f('spec')('applicationId'),
              e =>
                e.$eq(
                  ObjectId.createFromHexString(
                    this.$route.params.applicationId,
                  ),
                ),
            ),
          update =>
            update.$pull(p =>
              p(
                f => f('spec')('labels'),
                'value',
                this.list[this.index].name + ':' + this.currentValue,
              ),
            ),
        );
        this.$emit('updateList');
      }
    } else if (this.type === 'used') {
      const usedProductLabel = await usedProductLabels.update(
        filter =>
          filter(
            f => f('_id'),
            e => e.$eq(this.list[this.index]._id as ObjectId),
          )(
            f => f('spec')('applicationId'),
            e =>
              e.$eq(
                ObjectId.createFromHexString(this.$route.params.applicationId),
              ),
          ),
        update =>
          update.$pull(p =>
            p(f => f('spec')('values'), 'value', this.currentValue),
          ),
      );
      if (usedProductLabel.length > 0) {
        this.$emit('updateList');
      }
    } else if (this.type === 'point') {
      const groupDeliveryPointLabel = await groupDeliveryPointLabels.update(
        filter =>
          filter(
            f => f('_id'),
            e => e.$eq(this.list[this.index]._id as ObjectId),
          )(
            f => f('spec')('applicationId'),
            e =>
              e.$eq(
                ObjectId.createFromHexString(this.$route.params.applicationId),
              ),
          ),
        update =>
          update.$pull(p =>
            p(f => f('spec')('values'), 'value', this.currentValue),
          ),
      );
      if (groupDeliveryPointLabel.length > 0) {
        this.list[this.index].values.splice(this.valueIndex, 1);
        groupDeliveryPoints.update(
          filter =>
            filter(
              f => f('spec')('labels'),
              e =>
                e.$in([this.list[this.index].name + ':' + this.currentValue]),
            )(
              f => f('spec')('applicationId'),
              e =>
                e.$eq(
                  ObjectId.createFromHexString(
                    this.$route.params.applicationId,
                  ),
                ),
            ),
          update =>
            update.$pull(p =>
              p(
                f => f('spec')('labels'),
                'value',
                this.list[this.index].name + ':' + this.currentValue,
              ),
            ),
        );
        this.$emit('updateList');
      }
    } else if (this.type === 'question') {
      const questionLabel = await questionLabels.update(
        filter =>
          filter(
            f => f('_id'),
            e => e.$eq(this.list[this.index]._id as ObjectId),
          )(
            f => f('spec')('applicationId'),
            e =>
              e.$eq(
                ObjectId.createFromHexString(this.$route.params.applicationId),
              ),
          ),
        update =>
          update.$pull(p =>
            p(f => f('spec')('values'), 'value', this.currentValue),
          ),
      );
      if (questionLabel.length > 0) {
        this.list[this.index].values.splice(this.valueIndex, 1);
        questions.update(
          filter =>
            filter(
              f => f('spec')('labels'),
              e =>
                e.$in([this.list[this.index].name + ':' + this.currentValue]),
            )(
              f => f('spec')('applicationId'),
              e =>
                e.$eq(
                  ObjectId.createFromHexString(
                    this.$route.params.applicationId,
                  ),
                ),
            ),
          update =>
            update.$pull(p =>
              p(
                f => f('spec')('labels'),
                'value',
                this.list[this.index].name + ':' + this.currentValue,
              ),
            ),
        );
        this.$emit('updateList');
      }
    }
  }
}
